import { Tooltip } from "@mui/material";
import { MinusCircledIcon } from "@radix-ui/react-icons";
import { useState } from "react";
import {Spin} from 'antd'
import { remove } from "firebase/database";

export default function RemoveUserButton({removeUser}) {
    
    const [loading, setLoading] = useState(false)

    const removeU = async ()=> {
        setLoading(true)
        removeUser()
    }

    return (
        <>
        {
        loading ?
        <Spin />
        :
        <div onClick={removeU}>
            <Tooltip title="De-assign user">
                <MinusCircledIcon style={{cursor: "pointer"}}/>
            </Tooltip>
        </div>
        }
        </>
    )
}