import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import DataGridContainer from "../Items/DataGrid"
import { Link } from "react-router-dom"
import AssessmentEdit from "./AssessmentEdit"
import AssessmentSettings from "./AssessmentSettings"
import { getRequestAuth } from "../../Tools/Requests"
import Assignees from "./AssessmentAssignees"
import { Tabs } from "antd"

export default function AssessmentDetails() {
    
    const {test_id} = useParams()
    const [submissions, setSubmissions] = useState([])
    const [testTitle, setTestTitle] = useState("")

    useEffect(()=>{

        getRequestAuth(`/asmts/retrieve/submissions?test_id=${test_id}`)
        .then(async (res)=>{
            
            const submissionsArray = []

            for (let submissionData of res) {
                const submissionObj = {}

                submissionObj.id = submissionData.creation_data.id
                submissionObj.name = `${submissionData.creation_data.creator.first_name} ${submissionData.creation_data.creator.last_name}`
                submissionObj.status = submissionData.status
                submissionObj.score = submissionData.score
                submissionObj.date = submissionData.creation_data.date
                submissionObj.duration = submissionData.duration
                submissionObj.analysis = submissionData?.transcription?.content?.status ? submissionData.transcription.content.status : "processing"

                submissionsArray.push(submissionObj)

                }
            setSubmissions(submissionsArray)
            
        })

        getRequestAuth(`/asmts/retrieve/admin/assessment/title?test_id=${test_id}`)
        .then(async (res)=>{ 
            if (res.success) {
                setTestTitle(res.title)
            }   
            
        })
        
    }, [])

    const columns = [
        { 
            field: 'name', headerName: 'Name', width: 180,
            renderCell: (params) => <Link to={"/submissions/"+params.id}>{params.row.name}</Link>,
            valueGetter: (params) => params.row.name
        },
        { 
            field: 'date', headerName: 'Date', width: 100,
            renderCell: (params) => <Link to={"/submissions/"+params.id}>{params.row.date}</Link>,
            valueGetter: (params) => params.row.date
        },
        {
            field: 'duration',
            headerName: 'Duration',
            minWidth: 100,
            align: 'left',
            renderCell: (params) => <Link to={"/submissions/"+params.id}>{params.row.duration}</Link>,
            valueGetter: (params) => params.row.duration
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 100,
            align: 'left',
            renderCell: (params) => <Link to={"/submissions/"+params.id}>{params.row.status}</Link>,
            valueGetter: (params) => params.row.status
        },
        {
            field: 'score',
            headerName: 'Score',
            width: 100,
            align: 'left',
            renderCell: (params) => <Link to={"/submissions/"+params.id}>{params.row.score}</Link>,
            valueGetter: (params) => params.row.score
        },
        {
            field: 'analysis',
            headerName: 'Analysis',
            width: 100,
            align: 'left',
            renderCell: (params) => <Link to={"/submissions/"+params.id}>{params.row.analysis}</Link>,
            valueGetter: (params) => params.row.anaysis
        },
      ];
    
    const tabs = [
        {
            key: 0,
            children: <div>
                        <div className="sticky-head-table-manager" style={{marginLeft: 0}}>
                            <DataGridContainer selection={false} columns={columns} rows={submissions} title={"Submissions"}/>
                        </div>
                      </div>,
            label: "Submissions"
        },
        {
            key: 1,
            children: <Assignees />,
            label: "Assignees"
        },
        {
            key: 2,
            children: <AssessmentEdit />,
            label: "Edit"
        },
        {
            key: 3,
            children: <AssessmentSettings />,
            label: "Settings"
        }
    ]
     
    return (
        <div className="section-container">
            <h1>{testTitle}</h1>
            <Tabs items={tabs}/>
        </div>
    )
}