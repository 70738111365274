import { Modal, Button, Divider } from 'antd';
import {MainButton, PlainButton} from '../Items/Buttons.jsx';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import CategoryManagement from './CategoryManagement.jsx';
import RecentDocuments from './RecentDocuments.jsx';
import CategoryCard from './Partials/CategoriesCard.jsx';
import Trash from './Partials/Trash.jsx';
import Hidden from './Partials/Hidden.jsx';

export default function DocumentationContainer() {

    const [open, setOpen] = useState(false)

    const handleOpen = ()=> {
        setOpen(true)
    }

    return (
        <div className="section-container">
            <h2>Documentation</h2>
            <div style={{display: "flex", gap: 10}}>
                <Link to="/documentation/create">
                    <Button
                    style={{backgroundColor: "#4FBBB7", color: "white", fontWeight: 600}}
                    >New document</Button>
                </Link>
                <Button onClick={handleOpen}>Manage categories</Button>
            </div>
            <Modal 
                open={open}
                onOk={()=>setOpen(false)}
                onCancel={()=>setOpen(false)}
            >
                <CategoryManagement />
            </Modal>
            <div style={{marginTop: 30, display: "flex", flexWrap: "wrap", gap: 10}}>
                <RecentDocuments />
                <CategoryCard />
            </div>
            <div>
                <Divider />
                <div style={{display: "flex", flexWrap: "wrap", gap: 10}}>
                    <Hidden />
                    <Trash />
                </div>
            </div>
            
        </div>
    )
}