import {Input} from "antd";
const {Search} = Input;

export default function SearchBar({placeholder, searchHandler}) {
    return(
        <div>
            <Search 
            onChange={(e)=>searchHandler(e.target.value)}
            placeholder={placeholder} type="text"/>
        </div>
    )
}