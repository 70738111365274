import { useEffect, useState } from "react"
import BasicCardAdmin from "../Items/BoxAdmin"
import MessageBox from "../Items/MessageBox"
import { getRequestAuth } from "../../Tools/Requests"
import TestSkeleton from "./TestSkeleton"

export default function AdminTests({setAssessments, assessments}) {
    const [loaded, setLoaded] = useState(false)
    
    useEffect(()=> {
        getRequestAuth("/asmts/retrieve_organization")  
        .then(async (res)=> {      
            setAssessments(res.assessments)
            setLoaded(true)
        }
        )
    }, [])

    return (
            <div>
                <h3></h3>
                {
                loaded ?

                    (assessments?.length > 0 ?
                    
                    <div className="box-list">
                        {
                        assessments.map((test, index) => <BasicCardAdmin
                            category={test.category}
                            title={test.title}
                            duration={test.duration}
                            tags={test.tags}
                            test_id={test.test_id}
                            header={test.header_color}
                            language={test.language}
                            key={index}
                            ></BasicCardAdmin>)
                            }
                    </div>
                        :
                            <MessageBox title="No tests yet" message="Your organization hasn't created any tests yet." />)
                    :
                    <div style={{display: "flex", gap: 20, flexWrap: "wrap"}}>
                    <TestSkeleton />
                    <TestSkeleton />
                    <TestSkeleton />
                    <TestSkeleton />
                    </div>
                    }
            </div>
    )
}