import { deleteRequest, getRequestAuth, postRequest } from "../../Tools/Requests.js"
import UserSelector from "../Items/UserSelector.jsx"
import DataGridContainer from "../Items/DataGrid"
import {useParams, Link} from "react-router-dom"
import { useEffect, useState } from "react";
import { MinusCircledIcon } from "@radix-ui/react-icons";
import { Tooltip } from '@mui/material';
import { Card } from "antd";

export default function WorkflowUserManager({blueprintTitle}) {
    
    const {blueprint_id} = useParams();
    const [users, setUsers] = useState([]);

    const showUsers = ()=> {
        getRequestAuth(`/workflows/get_users/${blueprint_id}`)
        .then((res)=> {
            setUsers(res.users)
        })
    }

    const handleAddUser = (idList) => {

        const numberArray = idList.map(str => parseInt(str, 10));

        return postRequest("/workflows/add_users", {
            blueprintId: blueprint_id,
            users: numberArray,
            blueprintName: blueprintTitle,        
        }).then(()=> {
            showUsers();
        })
    }

    const removeUser = (id) => {
        deleteRequest(`/workflows/remove_user/${blueprint_id}`, 
            {
             userId: id,
             blueprintTitle: blueprintTitle   
            }
        )
        .then( ()=> showUsers())
    }

    useEffect(()=> {
        showUsers();
    }, [])

    const columns = [
        {
            field: 'name', headerName: 'Name', width: 300,
            renderCell: (params) => <div><Link to={`/candidate/${params.row.id}`}>{params.row.first_name} {params.row.last_name}</Link></div>,
            valueGetter: (params) => params.row.first_name + " " + params.row.last_name
        },
        {
            field: 'email', headerName: 'Email Address', width: 300,
            renderCell: (params) => <div>{params.row.email}</div>,
            valueGetter: (params) => params.row.email
        },
        {
            field: "id", headerName: "Action", width: 100,
            renderCell: (params) => <div onClick={()=> {removeUser(params.row.id)}}><Tooltip title="De-assign user"><MinusCircledIcon style={{cursor: "pointer"}}/></Tooltip></div>
        }
    ]

    return (
        <div style={{display:"flex", flexWrap: "wrap", gap: 10, alignItems: "flex-start"}}>
            <Card
                title={<h3 style={{padding:0, margin:0}}>Add interpreters to this blueprint</h3>}
                size="small"
                style={{height: "100%", width: 600}}
            >
                <UserSelector 
                    successMessage={"User added to blueprint."}
                    addUsersHandler={handleAddUser}
                />
            </Card>
            <div className="sticky-head-table-manager" style={{marginLeft: 0}}>
                <DataGridContainer rows={users} columns={columns} selection={false} title={"Interpreters assigned"}/>
            </div>
        </div>
    )
}