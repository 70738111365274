import { CubeIcon, FileTextIcon, Pencil1Icon } from "@radix-ui/react-icons"
import { useEffect, useState } from "react"
import Note from "./Note"
import { useParams } from "react-router-dom"
import { getRequestAuth, postRequest } from "../../Tools/Requests"
import { Drawer, Input, List } from "antd"
import { MainButton, LoadingButton } from "../Items/Buttons"
import Editor from "../Items/Editor"

export default function NotesSection({data}) {
    const [openNewNote, setOpenNewNote] = useState(false)
    const [notes, setNotes] = useState([])
    const [newNote, setNewNote] = useState({})
    const [newNoteTitle, setNewNoteTitle] = useState("New note")
    const [sendingNote, setSendingNote] = useState(false)

    const [editorData, setEditorData] = useState({});
    const [readOnly, setReadOnly] = useState(false);

    const { user_id } = useParams();

    const addNote = ()=> {

        if (newNoteTitle.length === 0) {
            setOpenNewNote(false)
            return
        }
        
        setSendingNote(true)
        const body = {
            title: newNoteTitle,
            editorData: editorData,
            userId: user_id
        }
        
        postRequest("/interpreter_data/new_note", body)
        .then((res) => {
            setEditorData({})
            setNewNoteTitle("New Note")
            setNewNote({})
            getNotes();
            setOpenNewNote(false)
            setSendingNote(false)
        })
        setEditorData({})     
    }

    const getNotes = ()=> {
        setNotes([]);
        getRequestAuth("/interpreter_data/get_user_notes/" + user_id)
        .then((res)=> 
        {
            setNotes(res);
        }
        )
    }

    useEffect(getNotes, []);

    return (
        <div className="candidate-data-table">
            <div className="candidate-data-header">
                <div style={{display: "flex", justifyContent: "space-between", position: "relative", alignItems:"center"}}>
                    <p>Notes</p>
                    <p onClick={()=>setOpenNewNote(true)} style={{color: "#4fbbb7", fontWeight: 600, display: "flex", alignItems: "center", fontWeight:400, fontSize:"14px", position: "absolute", left: "350px", cursor:"pointer"}}>New <FileTextIcon /></p>
                </div>
            </div>
            <div className="notes-list" style={{backgroundColor: "white"}}>
                <List  
                    size="small"
                    dataSource={notes}
                    renderItem={(note) => (
                        <List.Item>
                            <Note content={note} getNotes={getNotes}/>
                        </List.Item>
                    )}
                    />
            </div>
            <Drawer 
                onClick={(e) => e.stopPropagation()} // Prevent propagation
                title={<Input variant="borderless" value={newNoteTitle} style={{fontSize: 16, fontWeight: 500}} onChange={(e)=>setNewNoteTitle(e.target.value)}/>}
                placement="right"
                onClose={()=>setOpenNewNote(false)}
                open={openNewNote}
                width={"45vw"}
                extra={
                    sendingNote ?
                    <LoadingButton text="Saving" type="main" />
                    :
                    <div onClick={addNote}><MainButton text={"Save"}/></div>
                }
            >
                <Editor 
                    editorData={editorData}
                    setEditorData={setEditorData}
                    readOnly={readOnly}
                    setReadOnly={setReadOnly}
                    placeholder={"Write a new Swiftt note."}
                />
            </Drawer>
        </div>
    )
}