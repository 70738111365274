import "./Workflows.css";
import AddIcon from '@mui/icons-material/Add';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { useState, useEffect } from "react";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button } from "@mui/material";
import { getRequestAuth } from "../../Tools/Requests";

export default function SpeedDialWF({addTest}) {

    const [open, setOpen] = useState(false)
    const [assessments, setAssessments] = useState([])
    const [selected, setSelected] = useState({})

    const handleClose = () => {
        setOpen(false)
    }

    useEffect(
        ()=> {
            if (open) {
                getRequestAuth("/asmts/retrieve_organization_simple")
                .then(async (res)=> {
                    setAssessments(res.result)    
                })
            }
        }
    , [open])

    const handleAdd = () => {
        addTest(selected)
        handleClose()
    }

    return (
        <div className="speed-dial">
           <AddIcon style={{height: 30, width: 30}} onClick={()=> setOpen(true)}/>
           <Dialog onClose={handleClose} open={open}>
                <DialogTitle style={{fontSize: 15, width: 300, display: 'flex', justifyContent: 'center'}}>Add interpreter assessment</DialogTitle>
                {
                    assessments.length > 0 &&
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selected}
                            onChange={(e) => setSelected(e.target.value)}
                            size="small"
                            style={{width: 200}}
                        >
                    { assessments.map(
                            (assessment) => {
                                return(
                                    <MenuItem size="small" value={assessment}>{assessment.title}</MenuItem>
                                    )}
                        )}
                        
                        </Select>
                    </div>           
                }
                <Button style={{color: "#263238"}} onClick={handleAdd} variant="text">Add</Button>
            </Dialog>
        </div>
    )
}