import profilePicture from "../../Assets/profile-picture.webp";
import { CopyIcon } from "@radix-ui/react-icons";
import { useState } from "react";

export default function CandidateBanner({data}) {

    const [showCopy, setShowCopy] = useState(false)

    const copy = async ()=> {
        try {
            await navigator.clipboard.writeText(data.identity.primary_email);
          } catch (e) {
            console.log(e)
          }
    }
  
    return (
        <div className="candidate-profile-banner">
            <div className="candidate-banner-background"></div>
            <div className="d-flex">
                <img src={data?.profile_assets?.profile_picture ? data.profile_assets.profile_picture : profilePicture} alt="candidate profile picture" />
                <div className="candidate-profile-banner-data">
                    <div className="candidate-profile-identity-info">
                        <p>{data?.identity?.first_name} {data?.identity?.last_name}</p>
                        <p>Status: {data?.organization?.status}</p>
                    </div>
                    <div className="candidate-profile-contact-info">
                        <p onMouseEnter={()=> setShowCopy(true)} onMouseLeave={()=> setShowCopy(false)}>Email: {data?.identity?.primary_email} <span className={`copy-icon ${!showCopy ? "d-none" : ""}`}><CopyIcon onClick={copy}/></span></p>
                        <p>Phone: {data?.contact?.phone_number}</p>
                        <p>Address: {data?.contact?.address}, {data?.contact?.city}, {data?.contact?.country}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}