import {Input} from 'antd'

export default function Title({readOnly, documentTitle, setter}) {
    return (
        <div>
        {
            readOnly ?
            <h2 style={{marginBottom: 5}}>{documentTitle}</h2>
            :
            <Input.TextArea
                autoSize 
                variant="borderless"
                onChange={(e)=>setter((p)=>({...p, title: e.target.value}))}
                defaultValue={documentTitle}
                height={100}
                style={{fontSize: 25, width: "65vw", paddingLeft:0}}
            />
        }
        </div>
    )
}