import { useEffect, useState } from 'react';
import {MainButton, LoadingButton} from '../Items/Buttons.jsx';
import { Input, List } from 'antd';
import { deleteRequest, getRequestAuth, postRequest, putRequest } from "../../Tools/Requests";
import { StarIcon, StarFilledIcon } from '@radix-ui/react-icons';
import { highlightedOptions } from '../Navigation/Sidebar/options/options.jsx';
import { useAtom } from 'jotai';

export default function CategoryManagement() {

    const [subOptions, setSubOptions] = useAtom(highlightedOptions);

    const [categories, setCategories] = useState([])
    const [filteredCategories, setFilteredCategories] = useState([])
    const [newName, setNewName] = useState("")
    const [loading, setLoading] = useState(false)
    const [selectedCat, setSelectedCat] = useState(0);

    useEffect(()=> {
        const filtered = categories.filter((c)=>c.highlighted === true)    
        setSubOptions(filtered)

    }, [categories])

    const handleSaveCat = () => {
        if (newName.length === 0) {
            return;
        }

        setLoading(true)
        postRequest("/documents/new_category", {
            categoryName: newName
        })
        .then((res)=> {
            if (res.success) {
                setLoading(false)
                setNewName("")
                getCats();
            }
        })
    }

    const getCats = () => {
        getRequestAuth("/documents/categories")
        .then((res)=> {
            setCategories(res.categories);
            setFilteredCategories(res.categories);
        })
    }

    const handleRemoveCat = (id) => {
        
        if (id === selectedCat) {
            setSelectedCat(0);
            return;
        } else {
            deleteRequest("/documents/category/"+id)
            .then((res)=> {
                if (res.success) {
                    getCats();
                }
            })
        }
    }

    const handleEdit = (id) => {
        if (id === selectedCat) {
            putRequest("/documents/category/"+id, {
                newName: newName
            }).then(
                (res)=> {
                    if (res.success) {
                        setSelectedCat(0);
                        getCats();
                        setNewName("");
                    }
                }
            )
        } else {
            setSelectedCat(id);
        }
        
    }

    const handleFilter = (e) => {
        const termL = e.target.value.toLowerCase();

        const filtered = categories.filter((c) =>
          c.name?.toLowerCase().includes(termL)
          
        );
       
        termL.length === 0 ? setFilteredCategories(categories) : setFilteredCategories(filtered);
    }

    const handleHighlight = (id, highlighted) => {
        putRequest("/documents/category/highlight/"+id, {
            highlight: !highlighted
        })
        .then(()=> {
            getCats();
        })
       
    }

    useEffect(()=> {
        getCats();
    }, [])

    return (
        <>
        <h3>Document categories</h3>
            <div style={{display: "flex", flexDirection: "column", gap:20}}>
                <div style={{display: "flex", flexDirection: "row", gap: 10}}>
                    <Input 
                        placeholder='New category'
                        onChange={(e)=>setNewName(e.target.value)}
                        value={newName}
                    />
                    {
                        loading ?
                        <LoadingButton text="Saving" type="main" />
                        :
                        <div onClick={handleSaveCat}><MainButton text="Save" /></div>
                    }
                </div>
                <Input
                    placeholder="Search categories"
                    onChange={handleFilter}
                    style={{
                    width: 200,
                    }}
                    />
                <List 
                    size="small"
                    pagination={{
                        onChange: (page) => {
                            console.log(page);
                        },
                        pageSize: 5,
                        }}
                    dataSource={filteredCategories}
                    renderItem={(item)=> 
                        <List.Item style={{display: "flex", justifyContent: "space-between"}}>
                            
                            {
                                selectedCat === item.id ?

                                <Input 
                                    bordered={false}
                                    defaultValue={item.name}
                                    style={{width:350}}
                                    onChange={(e)=> setNewName(e.target.value)}
                                />
                                :
                                <span style={{display: "flex", alignItems:"center"}}>
                                    {
                                        item.highlighted ?
                                        <StarFilledIcon style={{color: "#F1C40F", cursor:"pointer"}} onClick={()=>handleHighlight(item.id, item.highlighted)}/>
                                        :
                                        <StarIcon style={{color: "#D0D3D4", cursor:"pointer"}} onClick={()=>handleHighlight(item.id, item.highlighted)}/>
                                    
                                    } {item.name}
                                </span>
                            } 
                            <div>
                                <span style={{cursor: "pointer", color: "blue"}} onClick={()=>handleEdit(item.id)}>{selectedCat === item.id ? "Save" : "Edit"}</span> | 
                                <span onClick={()=>handleRemoveCat(item.id)} style={{cursor: "pointer", color: "red"}}>{selectedCat === item.id ? " Cancel" : " Remove"}</span>
                            </div>
                        </List.Item>}
                />
            </div>
        </>
    )
}