import "./ProfileInfo.css"
import { useEffect, useRef, useState } from "react"
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import AddIcon from '@mui/icons-material/Add';
import AddLanguageModal from "../../Items/AddLanguage";
import ClearIcon from '@mui/icons-material/Clear';
import LanguageList from "./LanguageList";
import { deleteRequest, getRequestAuth, postRequest, postRequestFormData } from "../../../Tools/Requests";
import { Card, Input, Select, Button } from "antd";
import { useAtom } from "jotai";
import { personalPicture } from "../../Navigation/Sidebar/options/options";
const {TextArea} = Input;

export default function ProfileInfo({user}) {

    const [personal, setPersonal] = useState({})
    const [contact, setContact] = useState({})
    const [countries, setCountries] = useState([])
    const [languageList, setLanguageList] = useState([])
    const [profilePictureUpload, setProfilePictureUpload] = useState("")

    const [picture, setPicture] = useAtom(personalPicture)

    const [openAddLanguage, setOpenAddLanguage] = useState(false)

    const [reload, setReload] = useState(true)

    useEffect(()=> {

        // Fetch countries
        getRequestAuth("/prl/country_list")
        .then(async (res) => {
            setCountries(res);
        })

        // Fetch languages
        getRequestAuth("/asmts/languages")
        .then(async (res) => {
            setLanguageList(res);
        })

    }, [])

    const updateData = (values) => {
        
        postRequest("/prl/update",{
            newValues: values
        })
    }

    const addLanguage = (languageObject)=> {
        postRequest("/prl/update", {
            languages: languageObject,
            updateLanguages: true
        })
        .then((res) => {
            setOpenAddLanguage(false)
            setReload(!reload)
        })
    }

    const removeLanguage = (languageId)=> {
        deleteRequest("/prl/remove_language", {
            language_id: languageId
        })
    }

    const uploadProfilePicture = () => {

        postRequestFormData(
          "/prl/profile_picture",
          profilePictureUpload, 
          "image", 
          [{label: "previousImage", value: picture}]
        )
        .then((res)=> {
          if (res.success){
            setPicture(res.newURL)
            setProfilePictureUpload("")
          }
        })
    }
    const hiddenFileInput = useRef(null)

    const clickFileUpload = () => {
        hiddenFileInput.current.click();
    }

    return (
      <div
        className="profileInfoStyles"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: 10,
        }}
      >
        <div className="personal-items">
          <Card
            size="small"
            title={<h3 style={{ margin: 0, padding: 0 }}>Personal</h3>}
          >
            <div className="profile-editor-input-fields">
              <div
                style={{
                  display: "flex",
                  gap: 10,
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Input
                  placeholder="First name"
                  defaultValue={user?.identity?.first_name}
                  onChange={(e) =>
                    setPersonal({
                      ...personal,
                      "identity.first_name": e.target.value,
                    })
                  }
                />
                <Input
                  defaultValue={user?.identity?.last_name}
                  onChange={(e) =>
                    setPersonal({
                      ...personal,
                      "identity.last_name": e.target.value,
                    })
                  }
                />
              </div>
              <p
                style={{
                  margin: 0,
                  padding: 0,
                  marginBottom: -10,
                  marginTop: 10,
                  fontWeight: 500,
                }}
              >
                Summary
              </p>
              <TextArea
                rows={7}
                defaultValue={user?.identity?.description_self}
                onChange={(e) =>
                  setPersonal({
                    ...personal,
                    "identity.description_self": e.target.value,
                  })
                }
              />
              <Button
                onClick={() => updateData(personal)}
              >
                Save
              </Button>
            </div>
          </Card>
          <Card
            size="small"
            title={
              <h3 style={{ margin: 0, padding: 0 }}>Upload profile picture</h3>
            }
            style={{ height: 280 }}
          >
            <div>
              <input
                type="file"
                accept="image/*"
                onChange={(event) =>
                  setProfilePictureUpload(event.target.files[0])
                }
                hidden
                ref={hiddenFileInput}
              />
              <div className="upload-box" onClick={clickFileUpload}>
                <p>Upload profile picture</p>
                <AddAPhotoIcon />
                {profilePictureUpload && (
                  <span className="profile-picture-upload">
                    {profilePictureUpload.name}{" "}
                    <ClearIcon
                      className="clear-icon"
                      onClick={() => setProfilePictureUpload(null)}
                    />
                  </span>
                )}
              </div>
              <Button
                style={{marginTop: 10}}
                onClick={uploadProfilePicture}
              >
                Upload
              </Button>
            </div>
          </Card>
        </div>
        <div>
          <Card
            size="small"
            title={<h3 style={{ margin: 0, padding: 0 }}>Contact</h3>}
          >
            <div>
              <div className="profile-editor-input-fields">
                <div
                  style={{
                    display: "flex",
                    gap: 10,
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Input
                    placeholder="Email address"
                    defaultValue={user?.identity?.primary_email}
                    onChange={(e) =>
                      setContact({
                        ...contact,
                        "identity.primary_email": e.target.value,
                      })
                    }
                  />

                  <Input
                    placeholder="Secondary email address"
                    defaultValue={user?.identity?.secondary_email}
                    onChange={(e) =>
                      setContact({
                        ...contact,
                        "contact.secondary_email": e.target.value,
                      })
                    }
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: 10,
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Input
                    placeholder="Address"
                    onChange={(e) =>
                      setContact({
                        ...contact,
                        "contact.address": e.target.value,
                      })
                    }
                    defaultValue={user?.contact?.address}
                  />

                  <Select
                    onChange={(e) =>
                      setContact({
                        ...contact,
                        "contact.country": e,
                      })
                    }
                    style={{ width: 270 }}
                    placeholder="Country"
                    defaultValue={user?.contact?.country}
                    options={countries.length > 0 && countries.map((country) => ({value: country.name, label: country.name})).sort((a, b) => a.label.localeCompare(b.label))}
                  >
                  </Select>
                </div>
                <Input
                  placeholder="Phone number"
                  onChange={(e) =>
                    setContact({
                      ...contact,
                      "contact.phone_number": e.target.value,
                    })
                  }
                  defaultValue={user?.contact?.phone_number}
                />
              </div>
            </div>
            <Button
              style={{marginTop: 10}}
              onClick={() => updateData(contact)}
            >
              Save
            </Button>
          </Card>
        </div>
        <div>
        <Card
            size="small"
            title={<h3 style={{margin: 0, padding: 0}}>Languages</h3>}
        >
         
          {user?.languages &&
            user.languages.map((language) => (
              <LanguageList
                language={language}
                handleRemoveLanguage={removeLanguage}
              />
            ))}
          <div
            className="plus-icon"
            onClick={() => {
              setOpenAddLanguage(true);
            }}
          >
            <AddIcon />
          </div>
          <AddLanguageModal
            openDialog={openAddLanguage}
            handleClose={() => setOpenAddLanguage(false)}
            languages={languageList}
            handleAddLanguage={addLanguage}
          />
          </Card>
        </div>
      </div>
    );
}