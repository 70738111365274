import "./App.css"
import Login from "./Components/Login/Login";
import Sidebar from "./Components/Navigation/Sidebar/Sidebar.jsx";
import TopBar from "./Components/Navigation/Sidebar/TopBar.jsx";
import TestContainer from "./Components/Containers/TestContainer";
import BasicCardContainer from "./Components/Containers/BasicCardContainer";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Profile from "./Components/Profile/Main/Profile";
import { useState, useEffect } from "react";
import TestCreatorContainer from "./Components/Assessments/TestCreatorContainer.jsx"
import Settings from "./Components/Settings/Settings";
import InterpreterManager from "./Components/InterpreterManager/InterpreterManager";
import ProfileContainer from "./Components/CandidateProfile/ProfileContainer";
import CreateAccount from "./Components/Registration/CreateAccount";
import AssessmentDetails from "./Components/Assessments/AssessmentDetails";
import SubmissionDetails from "./Components/Assessments/SubmissionDetails";
import BoardContainer from "./Components/Tasks/BoardContainer";
import WorkflowContainer from "./Components/Workflows/WokflowsContainer";
import WorkflowsList from "./Components/Workflows/WorkflowsList";
import UI from "./Components/Items/UI.jsx";
import { userAtom } from "./Contexts/Atoms";
import { useAtom } from "jotai";
import { postRequest } from "./Tools/Requests";
import ResetPassword from "./Components/Login/ResetPassword.jsx";
import DocumentationContainer from "./Components/Documentation/DocumentationContainer.jsx";
import NewDocument from "./Components/Documentation/NewDocument.jsx";
import DocumentView from "./Components/Documentation/DocumentView.jsx";
import CategoryView from "./Components/Documentation/CategoryView.jsx";
import AnalyticsContainer from "./Components/Analytics/AnalyticsContainer.jsx";

function App() {

  const [loggedIn, setLoggedIn] = useState(false)
  const [user, setUser] = useState({})
  const [currentUser, setCurrentUser] = useAtom(userAtom)

  useEffect(()=>{

    const authToken = localStorage.getItem('token')

    if (authToken) {
      postRequest("/auth/access")
      .then(async (res)=> {
        if (res.status === 200) {
          setLoggedIn(true)
          setUser(res.user)
          setCurrentUser(res.user)
        }  else {setLoggedIn(false)}
      })
        
    }
  }, [])
   
  return (
    
    
      <div className="app-grid">
        <Router>
        <TopBar loggedIn={loggedIn}/>
        <Sidebar loggedIn={loggedIn}/>
          <Routes>
            {/**** LOGGED IN ROUTES ****/}
            { loggedIn ?
              <>
                <Route path="/" element={<BasicCardContainer />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/login" element={<Login />} />
                <Route path="/tests" element={<BasicCardContainer />} />
                <Route path="/test/:test_id" element={<TestContainer />} />
              {/**** ADMIN ROUTES ****/}
              {user?.identity?.account_type === "Admin" &&
                <>
                  <Route path="/tests/create" element={<TestCreatorContainer/>} />
                  <Route path="/tests/:test_id/details" element={<AssessmentDetails/>} />
                  <Route path="/manage" element={<InterpreterManager/>}/>
                  <Route path="/candidate/:user_id" element={<ProfileContainer />} />
                  <Route path="/submissions/:submission_id" element={<SubmissionDetails />} />
                  <Route path="/tasks" element={<BoardContainer/>} />
                  <Route path="/blueprints" element={<WorkflowsList />} />
                  <Route path="/new-blueprint" element={<WorkflowContainer/>} />
                  <Route path="/blueprint/:blueprint_id" element={<WorkflowContainer />} />
                  <Route path="/ui-cheatsheet" element={<UI/>} />
                  <Route path="/documentation" element={<DocumentationContainer />} />
                  <Route path="/documentation/category/:category_id" element={<CategoryView />} />
                  <Route path="/documentation/create" element={<NewDocument />} />
                  <Route path="/documents/:document_id" element={<DocumentView />} />
                  <Route path="/trash/:document_id" element={<DocumentView />} />
                  <Route path="/analytics" element={<AnalyticsContainer />} />
                </>
              }
                <Route path="/settings" element={<Settings/>} />
              </>
              :
                <>
                  <Route path="/activate_account" element={<CreateAccount/>}/>
                  <Route path="*" element={ <Login /> }/>
                  <Route path="/reset-password" element={<ResetPassword/>}/>
                </>
            }
          </Routes>
        </Router>
      </div>
   
    
      
  );
}

export default App;
