import { useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { Card, Modal, Button } from "antd"
import { deleteRequest } from "../../Tools/Requests.js"

export default function AssessmentSettings() {

    const navigate = useNavigate()
    
    const [openConfirm, setOpenConfirm] = useState(false)

    const {test_id} = useParams();

    const deleteTest = () => {
        deleteRequest(`/asmts/delete?assessmentId=${test_id}`)
        setOpenConfirm(false)
        navigate("/")
    }

    return(
        <Card
            title={<h3 style={{padding:0, margin:0}} className="danger">Danger zone</h3>}
            size="small"
        >
            <div>
                <Button danger onClick={()=>setOpenConfirm(true)}>
                    Delete test
                </Button>
                <Modal
                    title="Are you sure you want to delete this test?"
                    open={openConfirm}
                    onOk={deleteTest}
                    onCancel={()=>setOpenConfirm(false)}
                    okButtonProps={{ style: { backgroundColor: 'red' } }}
                    okText="Confirm deletion" 
                >
                    <p>You are about to permantently delete a test from your organization.</p>
                    <p>You won't be able to recover this test later on. You can set this test as a draft if you don't want applicants to see it.</p>
                </Modal>
            </div>
        </Card>
    )
}