import "./ProfileContainer.css";
import { Drawer, Divider, Input, Dropdown } from 'antd';
import {useEffect, useState} from 'react';
import {MainButton, LoadingButton} from '../Items/Buttons'
import {TextField} from "@mui/material"
import { useParams } from "react-router-dom";
import { deleteRequest, putRequest } from "../../Tools/Requests";
import { DotsVerticalIcon, TrashIcon } from "@radix-ui/react-icons";
import Editor from "../Items/Editor";


export default function Note({content, getNotes}) {

    const [open, setOpen] = useState(false);
    const [updatedNote, setUpdatedNote] = useState(content.editorData ? content.editorData : {})
    const [updatedTitle, setUpdatedTitle] = useState(content.title)
    const [sendingNote, setSendingNote] = useState(false)
    const [unsavedChanges, setUnsavedChanges] = useState(false)
    const [readOnly, setReadOnly] = useState(true)

    const { user_id } = useParams();

    const updateNote = ()=> {
        setSendingNote(true)
        const body = {
            title: updatedTitle,
            editorData: updatedNote,
            candidateId: user_id,
            noteId: content.id,
            titleForTimeline: content.title
        }

        putRequest("/interpreter_data/update_note", body)
        .then((res)=> {
            getNotes()
            setUnsavedChanges(false)
            setSendingNote(false)
        })
        
    }

    const deleteNote = ()=> {
        deleteRequest(`/interpreter_data/delete_note/${content.id}`, {candidateId: user_id, noteTitle: updatedTitle})
        .then((res)=> {
            getNotes();
        })
    }

    const items = [
        {
            key: "1",
            label: (
                <div onClick={deleteNote} style={{display:"flex", alignItems: "center", gap: 5}}>
                    <TrashIcon/>
                    <span>Delete</span>
                </div>
            )
        }
    ]

    return(
        <div >
            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", gap: 50}}>
                <div className="note" onClick={()=>setOpen(true)}>
                    <div sx={{whiteSpace: "pre-wrap"}} className="note-body">
                        <p sx={{whiteSpace: "pre-wrap"}}>{content?.title?.length > 50 ? content?.title.slice(0, 50)+"..." : content?.title}</p>
                    </div>
                    <div className="note-author">
                        <p>{content.author}</p>
                        <p>{content.date.date} at {content.date.time12}</p>
                    </div>
                </div>
                <Dropdown menu={{items}} placement="bottomRight">
                    <DotsVerticalIcon style={{paddingRight: 10, cursor: "pointer"}}/>
                </Dropdown>
            </div>
            <Drawer 
                onClick={(e) => e.stopPropagation()} // Prevent propagation
                title={<Input variant="borderless" defaultValue={content.title} style={{fontSize: 16, fontWeight: 500}} onChange={(e)=>{setUpdatedTitle(e.target.value); setUnsavedChanges(true)}}/>}
                placement="right"
                onClose={()=>setOpen(false)}
                open={open}
                width={"45vw"}
                extra={
                    sendingNote ?
                        <LoadingButton text="Updating" type="main" />
                        :
                    <div onClick={updateNote}><MainButton text={"Update"}/></div>
                }
                >
                    <div style={{display: "flex", gap: 10}}>
                        <p className="note-author-internal">{content.author}</p>
                        <p>{content.date.date} at {content.date.time12}</p>
                    </div>
                    {unsavedChanges && <p style={{color: "#B3B6B7", fontStyle: "italic", padding: 0, marginTop:-50, position: "absolute", }}>Unsaved changes</p>}
                    <Divider style={{paddingBottom: 20, margin: -10}}/>
                    <Editor 
                        editorData={updatedNote}
                        setEditorData={setUpdatedNote}
                        readOnly={false}
                        setReadOnly={setReadOnly}
                        holderId={content.id}
                        placeholder={"Write a new Swiftt note."}
                    />
            </Drawer>
        </div>
    )
}