import WorkflowsBoard from "./WorkflowsBoard"
import { useEffect, useState } from "react"
import { getRequestAuth } from "../../Tools/Requests"
import { useParams } from "react-router-dom"
import {Tabs} from 'antd';
import WorkfflowMap from "./WorkflowMap"
import WorkflowUserManager from "./WorkflowUserManager"
import { Skeleton } from "@mui/material"
import WorkflowsSettings from "./WorkflowsSettings"

export default function WorkflowContainer() {

    const [loading, setLoading] = useState(true)
    const [title, setTitle] = useState("")

    const [workflowID, setWorkflowID] = useState(0)
    const [workflow, setWorkflow] = useState({})

    const {blueprint_id} = useParams();

    useEffect(()=> {
        if (blueprint_id) {
            getRequestAuth(`/workflows/get_workflow/${blueprint_id}`)
            .then((res) => {
                setWorkflowID(blueprint_id)
                setWorkflow(res.blueprint)
                setTitle(res.blueprint.name)
                setLoading(false)
            })
    
        } else {
            setWorkflowID(Date.now())
            setLoading(false)
        }



    }, [])

    const tabs = [
        {
            key: 0,
            children: <WorkfflowMap loading={loading} title={title} setTitle={setTitle} workflowID={workflowID} workflow={workflow}/>,
            label: "Map"
        },
        {
            key: 1,
            children: <WorkflowUserManager blueprintTitle={title}/>,
            label: "Users"
        },
        {
            key: 2,
            children: <WorkflowsSettings />,
            label: "Settings"
        }
    ]

    return (
        <div className="section-container">
            <h1>{
                blueprint_id ? 
                    loading ?
                        <Skeleton style={{margin: 0}} width={200} height={28.66}/>
                        :
                        workflow.name 
                    
                    : "New blueprint"
                }
            </h1>
            <Tabs 
                items={tabs}
            />
        </div>
    )
}