import { TextField } from "@mui/material"
import WorkflowsBoard from "./WorkflowsBoard"

export default function WorkfflowMap({loading, title, setTitle, workflowID, workflow}) {
    return (
        <>
        {
            !loading ?
        <div>
            <TextField label="Blueprint name" variant="standard"
                inputProps={{
                    style: {
                        height:"50px",
                        width: "500px",
                        fontSize: "2rem",
                        padding: "0px",
                        color: "#626567",
                    },
                }}
                defaultValue={title}
                onChange={(e)=>{setTitle(e.target.value)}}
            />
            <WorkflowsBoard workflowTitle={title} workflowID={workflowID} workflowObject={workflow}/>
        </div>
        :
        <p>Loading</p>
        }
        </>
    )
}