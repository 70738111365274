import { useCallback, useEffect } from 'react';
import ReactFlow, {
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
} from 'reactflow';
import CustomNode from './CustomNode';
import ButtonEdge from './CustomEdge';
import 'reactflow/dist/style.css';
import './Workflows.css'
import SpeedDialWF from './SpeedDialWF';
import { userAtom } from '../../Contexts/Atoms';
import { useAtom } from 'jotai';
import { useState } from 'react';
import { getRequestAuth, postRequest } from '../../Tools/Requests';
import { LoadingButton, MainButton, SecondaryButton } from '../Items/Buttons';
import { useNavigate } from "react-router-dom"

const nodeTypes = {
    custom: CustomNode,
  };

const edgeTypes = {
  buttonedge: ButtonEdge,
};

export default function WorkflowsBoard({workflowTitle, mode, workflowID, workflowObject}) {

  const navigate = useNavigate();
  const [saveProcess, setSaveProcess] = useState(false);
  
  const initialNodes = workflowObject.nodes ? workflowObject.nodes : [];

  const initialEdges = workflowObject.edges ? workflowObject.edges : [];

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  // Sets the nodes and edges methods that aren't retrievable from the backend
  useEffect(()=> {

    if (nodes.length === 0) {return};

    setNodes(prevNodes => prevNodes.map(node => ({
      ...node,
      data: {
        ...node.data,
        test: {
          ...node.data.test,
          setNodes: setNodes,
          setEdges: setEdges
        }
      }
    })));

    setEdges(prevEdges => prevEdges.map(edge => ({
      ...edge,
      data: {
        ...edge.data,
        setEdges: setEdges
      }
    })));

  })

  const [user] = useAtom(userAtom)

  const onConnect = useCallback(
    (params) => setEdges(
        
        (eds) => {
          params.animated = true
          params.style = params.sourceHandle === 'fail' ? {stroke: "red"} : {stroke: '#28B463'}
          params.type = "buttonedge" 
    
          params.data = {
            setEdges: setEdges, 
          }

          return addEdge(params, eds)
        }
        
        ), 
    [setEdges]
    );

  const addNode = (test) => {
    const id = String(Date.now())

    test.setNodes = setNodes;
    test.setEdges = setEdges;

    const newNodes = [...nodes, { id: id, position: { x: 140, y: 100 }, data: { test, initial: false }, type: 'custom' }]
    setNodes(newNodes)
  }

  const saveBlueprint = () => {

    setSaveProcess(true)

    const organizationId = user.organization_id

      const newWorkflow = {
        id: workflowID,
        name: workflowTitle,
        organization_id: organizationId,
        edges: edges,
        nodes: nodes
      }

      // post nodes and edges
      postRequest("/workflows/post_workflows", newWorkflow)
      .then(
        (res) => {
          setSaveProcess(false)
          navigate("/blueprints")
        }
      )
  }

  return (
    <div style={{ width: '75vw', height: '75vh' }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        proOptions={{hideAttribution: true}}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
      >
        <Controls />    
        <Background variant="dots" gap={12} size={1} />
        <SpeedDialWF addTest={addNode}/>
      </ReactFlow>
      <div style={{display: "flex", gap: 20, marginBottom: 30}}>
        {
          saveProcess ?
          <LoadingButton text="Save Blueprint" type="system" />
          :
        <div onClick={saveBlueprint}><MainButton text="Save Blueprint" /></div>
        }
        <div onClick={()=>navigate("/blueprints")}><SecondaryButton text="Cancel" /></div>
      </div>
    </div>
  );
}