import "./InterpreterManager.css"
import { useEffect, useState } from "react"
import DataGridContainer from "../Items/DataGrid"
import {Link} from "react-router-dom"
import { getRequestAuth } from "../../Tools/Requests"

export default function ManageUsers() {
    const [rows, setRows] = useState([])

    useEffect(()=>{
        getRequestAuth("/interpreter_data/get_all")
        .then(async (res)=> {
          const users = []
          
          for (let user of res.result) {
              const userObj = {}

              const userStatus = user.organization ? user.organization[0].status : (user.status)

              const userName = user.first_name == null ? user.id : `${user.first_name} ${user.last_name}`
              
              userObj.id = user.id
              userObj.name = {id: user.id, username: userName}
              userObj.status = userStatus
              userObj.date_invited = user.date_invited
              userObj.email_address = user.primary_email
              userObj.account_type = user.account_type

              users.push(userObj)
          }
          setRows(users)
          
        })
    },
    
    [])

    const columns = [
        { field: 'name', headerName: 'Name', width: 250, align: 'left',
          renderCell: (params) => (<Link to={`/candidate/`+params.row.name.id}>{params.row.name.username}</Link>),
          valueGetter: (params) => params.row.name.username
        },
        { field: 'email_address', headerName: 'Email address', width: 250, align: 'left' },
        {
            field: 'date_invited',
            headerName: 'Date invited',
          minWidth: 100,
          align: 'left',
        },
        {
            field: 'account_type',
            headerName: 'Account type',
          minWidth: 100,
          align: 'left'
        },
        {
            field: 'status',
            headerName: 'Status',
            renderCell: (params) => (<div className={`userAccountType-`+params.row.status}>{params.row.status}</div>),
            valueGetter: (params) => params.row.status,
            minWidth: 50,
            align: 'left',
        },
      ];

      return(
        <div className="sticky-head-table-manager">
            <DataGridContainer rows={rows} columns={columns} pageSize={9} boxWidth={915} selection={true} title={"User database"}/>
        </div>
      )
}