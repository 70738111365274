import { useDataGridProps } from "@mui/x-data-grid/DataGrid/useDataGridProps.js";
import {interpreters, tests, submissions} from "./searchOptions.js";
import {Select, Checkbox} from 'antd';
import { useEffect, useState } from "react";

export default function ModalContent({setRequest}) {
    
    const options = [interpreters, tests, submissions]
    const [currentDataset, setCurrentDataset] = useState({})

    const handleChange = (value) => {
        const newDataset = options.filter((o)=> o.dataset === value)[0]
        setCurrentDataset(newDataset)
    }

    const selectColumn = (designation, checked) => {
        // Create a new copy of the columns with updated 'ok' values
        const updatedColumns = currentDataset.columns.map(column => {
            if (column.designation === designation) {
                return { ...column, ok: checked };
            }
            return column;
        });
        
        // Create a new dataset object with the updated columns
        const updatedDataset = { ...currentDataset, columns: updatedColumns };
        setCurrentDataset(updatedDataset);
        setRequest(updatedDataset);
    }

    return (
        <div>
            <h4>From:</h4>
            <Select 
                placeholder={"Select data"}
                style={{width: 150}}
                options={options.map((o)=> ({value: o.dataset, label: o.dataset}))}
                onChange={handleChange}
            />
            {
                currentDataset?.columns &&
                <>
                <h4>Select rows: </h4>
                <ul style={{listStyle: "none", padding: 0}}>
                {
                    currentDataset.columns.map((field, i) =>
                    <li 
                       key={i} style={{width: 200, display: "flex", justifyContent: "space-between"}}
                    >{field.display} {<Checkbox defaultChecked={field?.ok || false} onChange={(e)=> selectColumn(field.designation, e.target.checked)}/>}</li>
                )
                }
                </ul>
                </>
            }
        </div>
    )
}