import { TextField, Radio, FormControlLabel, RadioGroup } from "@mui/material"
import {Card, Input} from "antd";
import { useState } from "react";
import BasicAlerts from "../Items/Alerts";
import { LoadingButton, MainButton } from "../Items/Buttons";
import { postRequest } from "../../Tools/Requests";

export default function InviteUsers() {
    
    const [emailAddress, setEmailAddress] = useState("");
    const [accountType, setAccountType] = useState("");
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState({status: false, message: ""})
    const [sendingInvitations, setSendingInvitations] = useState(false)
    
    const sendInvitationEmail = ()=> {

        if (emailAddress.length === 0) {
            setError({...error, status: true, message: "You must provide an email address."})
            setSendingInvitations(false)
            return;
        }

        if (accountType.length === 0) {
            setError({...error, status: true, message: "You must select an account type."})
            setSendingInvitations(false)
            return;
        }
        
        setSendingInvitations(true)
        postRequest("/accounts/activate", {
            email_address: emailAddress,
            account_type: accountType,
        })
        .then(async (res)=> {
            
            if (res.status === 500) {
                setError({...error, status: true, message: res.error})
                setSendingInvitations(false)
                return
            }

            setSuccess(true)
            setSendingInvitations(false)
        })
    
    }

    return(

        <div>
            <Card
            title={<h3 style={{padding:0, margin:0}}>Invite users</h3>}
            size="small"
            >
                
                {success && <BasicAlerts type="success" message="Success! An email invitation has been sent to this user to join your organization." closeHandler={()=>setSuccess(false)}/>}
                {error.status && <BasicAlerts type="error" message={error.message} closeHandler={()=>setError({...error, status: false})}/>}
                <div className="invite-users-controls">
                    <Input 
                        style={{width: "400px"}}
                        placeholder="User email address" 
                        onChange={(e)=> setEmailAddress(e.target.value)}
                        
                        />
                    <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="draft"
                            name="radio-buttons-group"
                            onChange={(e)=>{setAccountType(e.target.value)}}
                        >
                            <FormControlLabel value="Interpreter" control={<Radio />} label="Interpreter" />
                            <FormControlLabel value="Admin" control={<Radio />} label="Manager" />
                    </RadioGroup>
                </div> 
                <div onClick={sendInvitationEmail}>
                    {
                        sendingInvitations ?
                        <LoadingButton text="Invite users" type="main" />
                        :
                        <MainButton text="Invite users" />
                    
                    }
                </div>
            </Card>
        </div>
    )
}