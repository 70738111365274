import "./LanguageList.css";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useState } from "react";


export default function LanguageList({language, handleRemoveLanguage}) {

    const [hide, setHide] = useState(false)

    return (
        <div className="language-item" style={{display: `${hide && "none"}`}}>
            <div style={{display: "flex", gap: "50px"}}>
                <div><p style={{width: "300px"}}>{language.combination.source} to {language.combination.target}</p></div>
                <div><p style={{display: "flex", gap: "10px", fontWeight:"bold"}}>{language.services.opi && <span>OPI</span>}{language.services.vri && <span>VRI</span>}{language.services.rsi && <span>RSI</span>}</p></div>
                
            </div>
            <div>
                <div><p>Minute rate: ${language.combination.minute_rate}</p></div>
            </div>
            <div>
                <div><p>Hourly rate: ${language.combination.hourly_rate}</p></div>
            </div>
            <div className="language-item-delete-forever"><DeleteForeverIcon onClick={()=> {handleRemoveLanguage(language.id); setHide(true)}}/></div>
        </div>
        
    )
}