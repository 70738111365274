export function getRequest(endpoint) {
    return fetch(endpoint, {
        method: 'GET',
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`Request failed with status: ${response.status}`);
          }
          return response.json();
        });
}

export function getRequestAuth(endpoint) {
    return fetch(endpoint, {
        method: 'GET',
        headers: new Headers({
            'Authorization': 'Basic ' + localStorage.getItem('token'),
            'Content-Type': 'application/x-www-form-urlencoded'
          }),
        },)
        .then(async (response) => {
          if (!response.ok) {
            throw new Error(`Request failed with status: ${response.status}`);
          }
          return await response.json();
        });
}

export function postRequestFormData(endpoint, file, name, additional) {
  const formData = new FormData();
  formData.append(name, file);
  if (additional) {
    for (let add of additional) {
      formData.append(add.label, add.value)
    }
  }
  return fetch( endpoint, {
      method: 'POST',
      headers: new Headers({
          'Authorization': 'Basic ' + localStorage.getItem('token'),
          }),
      body: formData
  }).then(async response => {
    
      if (!response.ok) {
        return await response.json();
      }
      
      return response.json();
    });
}

export function postRequest(endpoint, body) {
    return fetch( endpoint, {
        method: 'POST',
        headers: new Headers({
            'Authorization': 'Basic ' + localStorage.getItem('token'),
            'Content-Type':'application/json',
            }),
        body: JSON.stringify(body)
    }).then(async response => {
      
        if (!response.ok) {
          return await response.json();
        }
        
        return response.json();
      });
}

export function postRequestNoAuth(endpoint, body) {
  return fetch( endpoint, {
      method: 'POST',
      headers: new Headers({
          'Content-Type': 'application/json',
          }),
      body: JSON.stringify(body)
  }).then(async response => {
      
      if (!response.ok) {
        return await response.json();
      }
      
      return response.json();
    });
}

export function putRequest(endpoint, body) {
    return fetch( endpoint, {
        method: 'PUT',
        headers: new Headers({
            'Authorization': 'Basic ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
            }),
        body: JSON.stringify(body)
    }).then(response => {
        if (!response.ok) {
          throw new Error(`Request failed with status: ${response.status}`);
        }
        return response.json();
      });
}

export function deleteRequest(endpoint, body) {

  return fetch(endpoint, {
    method: 'DELETE',
    headers: new Headers({
        'Authorization': 'Basic ' + localStorage.getItem('token'),
        'Content-Type': 'application/json',
        }),
    body: JSON.stringify(body)
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(`Request failed with status: ${response.status}`);
        }
        return await response.json();
      });
}
