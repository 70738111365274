import { Modal, Select, Checkbox, Button } from "antd"
import { GearIcon } from "@radix-ui/react-icons"
import { useEffect, useState } from "react";
import { getRequestAuth } from "../../../Tools/Requests";
import { Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function EditingCommands({postNew, document,setter, updatedData}) {

    const [sendingDocument, setSendingDocument] = useState(false);
    const [openSettings, setOpenSettings] = useState(false)
    const [categories, setCategories] = useState([])
    const [changes, setChanges] = useState("")

    const navigate = useNavigate();

    useEffect(()=> {
        setChanges("Unsaved changes")
    }, [updatedData])

    const getCats = () => {
        getRequestAuth("/documents/categories")
        .then((res)=> {
            setCategories(res.categories.map((c)=> ({value: c.id, label: c.name})));
        })
    }

    const handlePost = ()=> {
        setSendingDocument(true);
        postNew()
        .then(()=> {
            setSendingDocument(false)
            setChanges("Changes saved")
            if (document.specs.trash) {
                navigate("/documentation")
            }
        })        
    }

    useEffect(()=> {
        getCats();
    }, [])

    return(
        <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
            <div style={{display: "flex", gap: 20, alignItems: "center"}}>
                <Button
                    style={{backgroundColor: "#4FBBB7", color: "white", fontWeight: 600}}
                    loading={sendingDocument}
                    onClick={handlePost}
                >Save document</Button>
                <GearIcon height="20" width="20" style={{cursor: "pointer"}} onClick={()=> setOpenSettings(true)}/>
                <p style={{fontWeight: 400, fontSize: 14}}>{changes}</p>
                <Modal 
                    open={openSettings}
                    onOk={()=> setOpenSettings(false)}
                    onCancel={()=> setOpenSettings(false)}
                >
                    <div style={{display: "flex", flexDirection: "column", gap: 20}}>
                        <div>
                            <p style={{padding: 0, margin: 0}}>Document type</p>
                            <Select 
                                placeholder="Document type"
                                style={{width: 170}}
                                options={[{value: "Article", label: "Article"}, {value: "FAQ", label: "FAQ"}, {value: "Terms", label: "Terms and Conditions"}]}
                                onChange={(value)=> setter((p)=>({...p, specs:{...p.specs, type:value}}))}
                                defaultValue={document?.specs?.type}
                            />
                        </div>
                        <div>
                            <p style={{padding: 0, margin: 0}}>Category</p>
                            <Select 
                                placeholder="Select a category"
                                style={{width: 170}}
                                options={categories}
                                onChange={(value)=> setter((p)=>({...p, specs:{...p.specs, category:value}}))}
                                defaultValue={categories.filter((c)=> c.value === document?.specs?.category)[0]}
                            />
                        </div>
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <Checkbox 
                                onChange={(e)=>setter((p)=>({...p, specs:{...p.specs, hide: e.target.checked}}))}
                                defaultChecked={document?.specs?.hide}
                                >Hide</Checkbox>
                            <Checkbox  
                                defaultChecked={document?.specs?.lock}
                                onChange={(e)=>setter((p)=>({...p, specs:{...p.specs, lock:e.target.checked}}))}
                                >Lock</Checkbox>
                            <Divider style={{margin: 10}}/>
                            <Checkbox
                                defaultChecked={document?.type?.trash}
                                onChange={(e) => setter((p)=>({...p, specs:{...p.specs, trash: e.target.checked}}))}
                            >Send to trash</Checkbox>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    )
}