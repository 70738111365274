import * as React from 'react';
import "./SelectUserModal.css"
import { FormControl, FormGroup, FormControlLabel, InputLabel, MenuItem, FormLabel, OutlinedInput, InputAdornment } from "@mui/material"
import {Modal, Input, Checkbox, Select} from 'antd';

export default function AddLanguageModal({openDialog, handleClose, handleAddLanguage, languages}) {

    const [languageCombination, setLanguageCombination] = React.useState({})
    const [OPI, setOPI] = React.useState(false)
    const [VRI, setVRI] = React.useState(false)
    const [RSI, setRSI] = React.useState(false)

    const languageObject = {
        id: Date.now(),
        combination: languageCombination,
        services: {
            opi: OPI,
            vri: VRI,
            rsi: RSI
        }
    }

    return (
      <div>
        <Modal
          open={openDialog}
          onCancel={handleClose}
          onOk={() => handleAddLanguage(languageObject)}
          title={
            <h3 style={{ margin: 0, padding: 0 }}>
              Add a new language combination
            </h3>
          }
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div style={{width: "100%", display:"flex", gap: 10}}>
              <Select
                placeholder="Source language"
                onChange={(value) =>
                  setLanguageCombination({
                    ...languageCombination,
                    source: value,
                  })
                }
                options={languages.map((language) => ({label: language.name, value: language.name})).sort((a, b) => a.label.localeCompare(b.label))}
              />
             
              <Select
                placeholder="Target language"
                onChange={(value) =>
                  setLanguageCombination({
                    ...languageCombination,
                    target: value,
                  })
                }
                options={languages.map((language) => ({label: language.name, value: language.name})).sort((a, b) => a.label.localeCompare(b.label))}
              />
              
            </div>
            <div style={{ width: "100%" }}>
              <p style={{
                  margin: 0,
                  padding: 0,
                  marginBottom: 0,
                  marginTop: 10,
                  fontWeight: 500,
                }}>Type</p>
              <Checkbox onChange={() => setOPI(!OPI)}>OPI</Checkbox>
              <Checkbox onChange={() => setVRI(!VRI)}>VRI</Checkbox>
              <Checkbox onChange={() => setRSI(!RSI)}>RSI</Checkbox>
            </div>
            <div style={{ display: "flex", gap: 10, width: "100%" }}>
                
                <div>
                <p
                style={{
                  margin: 0,
                  padding: 0,
                  marginBottom: 0,
                  marginTop: 10,
                  fontWeight: 500,
                }}
              >Minute rate</p>
                <Input
                    prefix={"$"}
                  onChange={(e) =>
                    setLanguageCombination({
                      ...languageCombination,
                      minute_rate: e.target.value,
                    })
                  }
                    placeholder="Minute rate"
                  type="number"
                />
                </div>
            <div>
            <p
                style={{
                  margin: 0,
                  padding: 0,
                  marginBottom: 0,
                  marginTop: 10,
                  fontWeight: 500,
                }}
              >Hourly rate</p>
                <Input
                prefix={"$"}
                  onChange={(e) =>
                    setLanguageCombination({
                      ...languageCombination,
                      hourly_rate: e.target.value,
                    })
                  }
                 
                  placeholder="Hourly rate"
                  type="number"
                />
                </div>
              
            </div>
          </div>
        </Modal>
      </div>
    );
}
