import BasicAlerts from "../Items/Alerts"
import { useState } from "react"
import { postRequest } from "../../Tools/Requests"
import { LoadingButton, SystemButton } from "../Items/Buttons"
import {Button, Input} from 'antd';

export default function ResetRequest() {

    const [load, setLoad] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [emailAddress, setEmail] = useState("")

    const sendReset = () => {
        setLoad(true)

        postRequest("/accounts/send_reset_password_email", {
            emailAddress: emailAddress
        })

        setShowAlert(true)
        setLoad(false)
    }


    return (
      <>
        <div className="login-presentation" style={{ marginBottom: 20 }}>
          <div className="login-presentation-text">
            <h2>Reset Password</h2>
            <p>Lost your keys? Don't worry, let us take care of it.</p>
          </div>
        </div>
        <div className="login-form-fields">
          {showAlert && (
            <BasicAlerts
              sx={{ width: 90 }}
              type="success"
              message="We have sent the password reset link to the email address you have provided."
              closeHandler={() => {
                setShowAlert(false);
              }}
            />
          )}

          <Input onChange={(event) => setEmail(event.target.value)} placeholder="Enter your email address"/>

          <div className="login-button-container">
            <Button onClick={sendReset} loading={load}>
              Send reset link
            </Button>
          </div>
        </div>
      </>
    );
}