import "./Assessment.css";
import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { ReactMic } from "react-mic";
import { Card, Button, Divider, Tag } from 'antd';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import CallEndIcon from '@mui/icons-material/CallEnd';
import { MinusIcon } from "@radix-ui/react-icons";
import img from "../../Assets/sampleCaller.webp";
import { postRequestFormData } from "../../Tools/Requests";


export default function Assessment({currentAssessment}) {

    const navigate = useNavigate()
    const [record, setRecord] = useState(false);
    const {test_id} = useParams();
    const [audio, setAudio] = useState(new Audio())
    const [sending, setSending] = useState(false)

    useEffect(()=> {
        if (currentAssessment.link_to_file) {
            // THIS SETS THE AUDIO
            setAudio(new Audio(currentAssessment.link_to_file))
        }
    }, [])
        
    // start the audio (using the .current property of the ref we just created)
    const playAudio = () => {
        audio.play()
        setRecord(true)
    }

    const stopAudio = () => {
        audio.pause()
        setRecord(false)
    }

    // Stop the audio when the component unmounts
    useEffect(() => {
        return () => {
            audio.pause()
        }
    }, [])

    const onStop = (recordedBlob) => {       
        audio.pause()
        const audioFile = new File([recordedBlob.blob], "audio.mp3", {type: "audio/mpeg"})

        const endpoint = `/api/audio/audiopost?test_id=${test_id}
        &ext_id=${currentAssessment.creation_data.org_id}
        &title=${currentAssessment.title}
        &transcribe=${currentAssessment.transcribe_submissions}
        `
        setSending(true)
        postRequestFormData(
            endpoint,
            audioFile,
            "blob"
        ).then((res)=> {
            if (res.success) {
                navigate("/")
            }
        })

      };

    return (

            <div className="session-under-way" style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", flexWrap: "wrap"}}>
                <Card style={{minWidth: 250, minHeight: 360}}>
                    <div style={{width: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", height: 10}}>
                        <MinusIcon style={{width: 20, height: 30, padding: 0, margin: 0, marginTop: -20, cursor: "pointer"}}/>
                    </div>
                    <Divider style={{width: "100%", margin: 0}} />
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <h3>Incoming call</h3>
                        <img src={img} style={{borderRadius: "50%", width: 100}} alt="caller"/>
                        <p>{currentAssessment.language.source} to {currentAssessment.language.target}</p>
                        <Tag color="magenta">{currentAssessment.category}</Tag>
                        <div className="waveform-elements">
                            <div className="waveform-bar">
                                <ReactMic
                                style={{display: "hidden"}}
                                record={record}         // defaults -> false.  Set to true to begin recording
                                visualSetting="none"  // sinewave or frequencyBar color
                                //strokeColor={"#11192A"}    
                                //backgroundColor={"#ffffff"}
                                onStop={onStop}
                                />
                            </div>
                        </div>
                    </div>
                    {
                        !sending ?
                    <div style={{display:"flex", position: "relative", alignItems: "center", justifyContent: "center"}}>
                        {!record && <div className={"call-animation"} onClick={playAudio}><Button shape="circle" style={{width: 50, height: 50, display: "flex", justifyContent: "center", alignItems: "center"}} icon={<LocalPhoneIcon />} /></div>}
                        <div onClick={stopAudio}><Button className={record ? "end-call-recording" : "end-call"} shape="circle" icon={<CallEndIcon />} /></div>
                    </div>
                    :
                    <p>sending submission...</p>
                    }
                </Card>
                <Divider type="vertical" style={{height: 300}}/>
                <Card style={{padding: 20}}>
                    <h2>{currentAssessment.title}</h2>
                    <p>{currentAssessment.duration}</p>
                    <p>Type: {currentAssessment.category}</p>
                    <p>{currentAssessment.description}</p>
                </Card>
            </div>
        
    )
}