import * as React from 'react';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import "./SelectUserModal.css"
import List from "./List.jsx"
import { Chip } from '@mui/material';
import { getRequestAuth, postRequest } from '../../Tools/Requests';
import { Modal, Input } from 'antd';

export default function FormDialog({openDialog, handleClose, testName, testId}) {

    const [idList, setIdList] = useState([])
    const [nameUserList, setNameUserList] = useState([])
    const [interpreterMatches, setInterpreterMatches] = useState([])

    const handleChange = (email)=>{
        
        getRequestAuth(`/interpreter_data/get_email_match?email=${email}`)
        .then(async (res)=> {
            const currentList = res.filter(user => {return !idList.includes(user.id) })
            setInterpreterMatches(currentList)
        })
    }

    const addUserTolist = (id, name) => {
        setInterpreterMatches(interpreterMatches.filter((user) => {return user.id != id}))
        setIdList([...idList, id.toString()])
        setNameUserList([...nameUserList, name])
    }

    const addUserstoTest = () => {
        if (idList.length > 0) {
            postRequest("/asmts/assign_to_users", {
                test_id: testId,
                user_ids: idList,
                testName: testName
            })
        }

        setInterpreterMatches([])
        setIdList([])
        setNameUserList([])
        
        handleClose()
    }

    return (
        <div>
        <Modal 
            open={openDialog} 
            onCancel={handleClose}
            onOk={addUserstoTest}
            title={<h3>Add user to test: {testName}</h3>}
        >            
            <p>Search a user by name or email address and click on the name to add them 
               to this test. Only users that have been added will have this test available.
            </p>
            <div>
                {
                nameUserList.length > 0 &&
                nameUserList.map(
                    (name, key) =>
                <Chip key={key} label={name} sx={{margin: "3px"}}/>
                )}
            </div>
            <Input
                placeholder="Email Address"
                onChange={(e)=>handleChange(e.target.value)}
            />
            <List userList={interpreterMatches} adduser={addUserTolist}/>
        </Modal>
        </div>
    );
}
