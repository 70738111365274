import { List } from "antd"
import { getRequestAuth } from "../../../Tools/Requests"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { EyeNoneIcon } from "@radix-ui/react-icons"

export default function Hidden() {
    const [documents, setDocuments] = useState([])
    
    const getDocuments = () => {
        getRequestAuth("/documents/hidden")
        .then((res)=> {
            console.log(res)
            if (res.success) {
                setDocuments(res.documents)
            }
        })
    }
    
    useEffect(()=> {
        getDocuments();
    }, [])
    
    return (
        <List
            style={{backgroundColor: "white", width: 700}}
            header={<><h3 style={{padding:0, margin:0, display: "flex", alignItems: "center", gap: 5}}><EyeNoneIcon/>Your hidden documents</h3><p style={{margin:0}}>List of documents that are only visible to you.</p></>}
            size="small"
            bordered
            dataSource={documents}
            pagination={{
                pageSize: 5,
                }}
            renderItem={(doc) => (
                <List.Item>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <span style={{fontWeight: 500, fontSize: "small"}}>{doc.specs.type} | {doc.date.date}</span>
                        <Link to={`/documents/${doc.id}`}>{doc.title}</Link>
                    </div>
                </List.Item>
            )}
    />
    )
}