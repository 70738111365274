import "./Tasks.css"
import { useState } from "react"

export default function NewTaskForm({handleAddItem, handleCancel, boardId}) {

    const [taskTitle, setTaskTitle] = useState("")
    const [comment, setComment] = useState("")
    const [pipeline, setPipeline] = useState("")

    const clear = () => {
        setTaskTitle("")
        setComment("")
        setPipeline("")
    }
    return(
        <div className="new-task-form-inner">
            <input value={taskTitle} placeholder="Task title" onChange={(e)=>setTaskTitle(e.target.value)}></input>
            <input value={comment} placeholder="Comment" onChange={(e)=>setComment(e.target.value)}></input>
            <input value={pipeline} placeholder="Blueprint"onChange={(e)=>setPipeline(e.target.value)}></input>
            <div className="new-task-form-options">
                <div onClick={()=> {handleCancel(); clear();}}><p>Cancel</p></div>
                <div onClick={()=> {handleAddItem(taskTitle, comment, pipeline, boardId); handleCancel(); clear()}}><p>Add task</p></div>
            </div>
        </div>
    )


}