import "./Sidebar.css";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { candidateOptions, adminOptions, highlightedOptions } from "./options/options";
import { getRequestAuth, postRequest } from "../../../Tools/Requests";
import { useAtom } from "jotai";
import { Divider } from "antd";
import Subitem from "./options/Subitem";

let sidebarOptions = candidateOptions;

export default function Sidebar({loggedIn}) {

    const [subOptions, setSubOptions] = useAtom(highlightedOptions)

    const [admin, setAdmin] = useState(false)
    const [pathname, setPathname] = useState("")
    const [translateY, setTranslateY] = useState("")
    const [backgroundColor, setBackgroundColor] = useState("")

    const [selectedSubitem, setSelectedSubitem] = useState(0)

    useEffect(()=> {
        if (loggedIn) {
            postRequest("/auth/access", )
            .then(async (res)=> {
            res.user.identity.account_type === "Admin" && setAdmin(true)
        })
        }

        if (loggedIn) {
        getRequestAuth("/documents/categories/highlighted")
        .then((res)=>{
            if (res.success) {
                setSubOptions(res.categories.filter((c)=>c.highlighted === true))
            }
        })

    }
    }, [loggedIn])

    
    if (window.location.pathname.match("/login")){
        return null;
      }

    const changePathname = (value)=> {
        setPathname(value.path)
        setTranslateY(value.translateY)
        setBackgroundColor(value.backgroundColor)
    }

    if (admin) {
        sidebarOptions = adminOptions;
    }
    

    return (
    
    <div className="sidebar-body">
        <div className="navbar-selector" style={{transform: `translateY(${translateY})`, backgroundColor: backgroundColor}}></div>
        <ul>
            {
                sidebarOptions.map((value, key) => {
                    return (
                        <Link key={key} to={value.path}  onClick={()=>changePathname(value)}>
                            <li id={pathname === value.path ? "selected-navbar" : ""}>
                                <div className="sidebar-main-options">
                                    <div>{value.icon}</div>
                                    <div>{value.title}</div>
                                </div>
                                {
                                    value.submenu &&
                                    <ul className={pathname === value.path ? "sidebar-submenu-options" : "d-delete"}>
                                        {
                                         subOptions.length > 0 &&
                                         subOptions.map((option, key) => {
                                            return (<Subitem isSelected={selectedSubitem} option={option} key={key} onSelect={setSelectedSubitem}/>)
                                         })   
                                        }
                                        <Divider style={{minWidth: "80%", width: "80%", margin: "8px auto",}}/>
                                    </ul>
                                }
                            </li>
                        </Link>
                    )
                })
            }
        </ul>
    </div>
    )
}