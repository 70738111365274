import {Input} from 'antd';

export default function Search({handleChange, handleSearch}) {
    return (
        <div className="search-input-top-bar">
            <Input.Search 
                placeholder="Search"
                variant="borderless"
                style={{color: "white"}}
                onChange={(e)=>handleChange(e.target.value)}
                onSearch={()=>handleSearch(true)}
            />
        </div>
    )
}