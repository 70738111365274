import { BarChart } from '@mui/x-charts/BarChart';
import { PieChart } from '@mui/x-charts/PieChart';
import { LineChart } from '@mui/x-charts/LineChart';
import {Card, Progress} from 'antd'
import "./SwifttCharts.css"

export function SwifttBar({title}) {
    return (
        <Card style={{padding:0}}>
        <p className="swiftt-title">{title}</p>
        <BarChart
          xAxis={[
            {
              id: 'barCategories',
              data: ['0', '1', '2', "3", "4", "5", "6", "7", "8", "9", "10"],
              scaleType: 'band',
            },
          ]}
          series={[
            {
              data: [2, 5, 10, 3, 15, 8, 8, 9, 10, 20, 6],
              color: "#4096ff"
            },
          ]}
          width={450}
          height={200}
        />
        </Card>
      );
}

export function SwifttPie({title}) {
  return (
    <Card>
      <p className="swiftt-title">{title}</p>
      <PieChart
        series={[
          {
            data: [
              { id: 0, value: 10, label: 'Passed', color: '#45B39D' },
              { id: 1, value: 15, label: 'Failed', color: '#F5B041' },
            ],
            color:"#4096ff"
          },
        ]}
        width={300}
        height={200}
      />
    </Card>
  );
}

export function Stat({stat, title}) {
      return (
      <Card className="swiftt-stat" style={{height: 120}}>
        <p className="swiftt-title">{title}</p>
        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
          <h1 style={{fontSize: 36, padding: 0, margin: 0, color:"#ffa940"}}>{stat}</h1>
        </div>
      </Card>
      )
}

export function SwifttLine({title}) {
  return (
    <Card>
      <p className="swiftt-title">{title}</p>
      <LineChart
        xAxis={[
          {
            id: 'lineCategories',
            data: ['Jan', 'Feb', 'Mar', "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dic"],
            scaleType: 'band',
          },
        ]}
        series={[
          {
            data: [2, 5, 10, 3, 15, 8, 8, 9, 10, 20, 6, 10],
            color: "#4096ff"
          },
        ]}
        width={900}
        height={300}
      />
    </Card>
  );
}

export function SwifttProgress({title}) {
  return (
    <Card className="swiftt-stat">
      <p className="swiftt-title">{title}</p>
      <Progress percent={62}
                status="active"
                strokeColor={{
                  from: '#108ee9',
                  to: '#87d068',
                }} 
        />
    </Card>
  )
}