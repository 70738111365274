import "./ProfileContainer.css";
import CandidateRecordData from "./CandidateRecordData.jsx";
import NotesSection from "./NotesSection.jsx";
import CandidateBanner from "./CandidateBanner.jsx";
import DataGridContainer from "../Items/DataGrid";
import AdvancedOptions from "./AdvancedOptions";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { getRequestAuth } from "../../Tools/Requests";

export default function ProfileContainer() {
    
    const [rows, setRows] = useState([])
    const [user, setUser] = useState({})

    const {user_id} = useParams()

    
    useEffect(()=>{
      
        getRequestAuth("/asmts/retrieve/admin/user_assessments/" + user_id)
        .then(async (res)=> {
          
          const processedRows = [];
          return;
          for (let row of res) {
              const rowObject = {};
              rowObject.id = row.creation_data.id;
              rowObject.title = {title: row.assessment.title, id: row.creation_data.id};
              rowObject.score = row.score;
              rowObject.status = row.status;
              rowObject.date = row.creation_data.date;
              
              processedRows.push(rowObject);
          }
          setRows(processedRows);
          
        })

        getRequestAuth("/interpreter_data/get_user_profile/" + user_id)
        .then(async (res)=> {
          setUser(res)
        })
    },
    
    [])

    const columns = [
        { field: 'title', headerName: 'Title', width: 300, align: 'left',
          renderCell: (params) => (<Link to={`/submissions/`+params.row.title.id}>{params.row.title.title}</Link>),
          valueGetter: (params) => params.row.title.title
        },
        {
          field: 'score', headerName: 'Score', width: 100
        },
        {
          field: 'status', headerName: 'Status', width: 100
        },
        {
          field: 'date', headerName: 'Date', width: 100
        }
      ];

    return (
        <div className="section-container">
            <CandidateBanner data={user} />
            <div className="candidate-data-container" style={{marginBottom: "50px"}}>
                <CandidateRecordData />
                <div className="candidate-content">
                    <NotesSection />
                </div>
            </div>
            <DataGridContainer rows={rows} columns={columns} title={"Submissions"}/>
            <AdvancedOptions user={user} updateCall={setUser}/>
        </div>
    )
}