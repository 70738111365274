import TestEditor from "./TestEditor.jsx"
import { useEffect, useState } from "react"
import {useParams, useNavigate} from "react-router-dom";
import { getRequestAuth, putRequest } from "../../Tools/Requests";
import {Spin} from 'antd'

export default function TestUpdaterContainer() {

    const navigate = useNavigate()

    const [languages, setLanguages] = useState([])
    const [loading, setLoading] = useState(false)
    const [test, setTest] = useState({})
    const [contentLoaded, setContentLoaded] = useState(false)

    const {test_id} = useParams()

    useEffect(()=> {

        getRequestAuth("/asmts/languages")
        .then((res)=>{
            setLanguages(res)
        })

        getRequestAuth(`/asmts/retrieve/admin/assessment?test_id=${test_id}`)
        .then(async (res)=>{ 
            if (res.success) {
                setTest(res.assessment)
                setContentLoaded(true)
            }   
            
        })

    }, [])


    const sendInfo = (content)=> {
        setLoading(true)
        putRequest(`/asmts/update_test?assessmentId=${test_id}`, content)
        .then((res)=>{
            if (res.success) {
                setLoading(false)
                navigate("/tests")
            }  
        })
    }
    

    return(
        <div>
        {
            !contentLoaded ?
        <Spin style={{marginLeft: 150, marginTop: 20}} tip="Loading" size="large"></Spin>
        :
        <TestEditor key={test.title} tlanguages={languages} testData={test} sendInfo={sendInfo} loading={loading} buttonLegend="Update test"/>
        }
        </div>
    )
}