import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import {Button, Tag, Divider} from 'antd';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import "./Box.css";

export default function BasicCard({category, title, duration, tags, test_id, header_id, language }) {
  return (
    <Card sx={{ minWidth: 275, border: "1px solid #e6e5e8", boxShadow: "none", maxWidth: 450 }}>
      <div className={`header-${header_id}`}>
      </div>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {category}
        </Typography>
        <Typography variant="h5" component="div">
          {title}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {duration}
        </Typography>
        <ul style={{listStyle: 'none', display: 'flex', gap: 5, padding: 0}}>
            {tags?.map((item, index) => <li key={index}><Tag color='default'>{item}</Tag></li>)}
        </ul>
        {language &&
        <Typography>
          <span>{language.source} to {language.target}</span>
        </Typography>
        }
      </CardContent>
      <CardActions>
        <Divider style={{padding:0, margin: "0 auto", width: "60%", minWidth: "60%"}}/>
        <Link to={"/test/" + test_id}>
          <Button>Start</Button>
        </Link>
      </CardActions>
    </Card>
  );
}
