import { Skeleton } from "@mui/material"

export default function TestSkeleton() {
    return(
        <div style={{maxWidth: 250, display: "flex", flexDirection: "column", gap: 8}}>
            <Skeleton width={250} />
            <Skeleton width={50} />
            <Skeleton variant={"h1"} width={250} height={40}/>
            <div style={{display: "flex", gap: 10}}>
                <Skeleton width={50} />
                <Skeleton width={50} />
                <Skeleton width={50} />
            </div>
                <Skeleton width={80}/>
            <div  style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                <Skeleton width={80} height={50} />
                <Skeleton variant="circular" width={40} height={40}/>
            </div>
        </div>
    )
}