import List from "../Items/List.jsx"
import { useState } from "react"
import { Button, Chip, TextField } from "@mui/material"
import BasicAlerts from "../Items/Alerts.jsx"
import { getRequestAuth } from "../../Tools/Requests"
import { MainButton, LoadingButton } from "./Buttons.jsx"
import {Input} from 'antd';

export default function UserSelector({successMessage, addUsersHandler }) {

    // For the button loader
    const [loading, setLoading] = useState(false)

    const [success, setSuccess] = useState(false)
    // List of names displayed in chips
    const [nameUserList, setNameUserList] = useState([])
    // List with the query results
    const [interpreterMatches, setInterpreterMatches] = useState([])
    // List of IDs that will be sent to backend for adding to solution
    const [idList, setIdList] = useState([])
    
    const [displayList, setDisplayList] = useState("d-hidden")

    const handleChange = (email)=>{
        if (email.length === 0) {
            setDisplayList("d-hidden");
            return;
        }
        setDisplayList("d-flex")

        getRequestAuth(`/interpreter_data/get_email_match?email=${email}`)
        .then((res)=> {
            
            const currentList = res.filter(user => {return !idList.includes(user.id.toString()) })
            setInterpreterMatches(currentList)
        })
    }

    const addHandler = () => {
        if (idList.length === 0) {
            return;
        }
        setLoading(true)
        addUsersHandler(idList)
        .then((res)=> {
            setNameUserList([])
            setIdList([])
            setInterpreterMatches([])
            setLoading(false)
            setSuccess(true)
        })
        
    }

    const addUserTolist = (id, name) => {
        setInterpreterMatches(interpreterMatches.filter((user) => {return user.id != id}))
        setIdList([...idList, id.toString()])
        setNameUserList([...nameUserList, {name:name, id:id}])
        setDisplayList("d-hidden")
    }

    const removeFromList = (id) => {

        // Filter out the user with the specified ID
        const updatedNameUserList = nameUserList.filter(user => user.id !== id);
        setNameUserList(updatedNameUserList);

        // Remove the ID from idList
        const updatedIdList = idList.filter(currentId => currentId !== id.toString());
        setIdList(updatedIdList);
    }

    return (
        <div style={{maxWidth: 400, display: "flex", flexDirection: "column", gap: 10}}>
            <div>
            {
                nameUserList.length > 0 &&
                nameUserList.map(
                    (user, key) =>
                <Chip key={key} label={user.name} sx={{margin: "3px"}} onDelete={()=> removeFromList(user.id)}/>
                )
            }
            </div>
            {
                success &&
                <BasicAlerts type="success" message={successMessage} closeHandler={()=> setSuccess(false)}/>
            }
            <div style={{position: "relative"}}>
            <Input
                placeholder="Email Address"
                onChange={(e)=>handleChange(e.target.value)}
            />
            <div className={displayList} style={{zIndex: 10, position: "absolute", top: 40}}>
                <List userList={interpreterMatches} adduser={addUserTolist}/>
            </div>
            </div>
            {
                idList.length > 0 &&
                
                    loading ?
                    <LoadingButton type="main" text="Adding users"/>
                    :
                    <div onClick={addHandler}>
                        <MainButton text="Add users" style={{color: "white"}} />
                    </div>
                
            }
        </div>
    )
}