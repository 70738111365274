import "../Assessment.css";
import { Card, Button, Divider, Tag } from 'antd';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import CallEndIcon from '@mui/icons-material/CallEnd';
import { MinusIcon } from "@radix-ui/react-icons";
import { useState } from "react";
import img from "../../../Assets/sampleCaller.webp"

export default function Caller({source, target, category}) {
    const [record, setRecord] = useState(false)
    return (
        <Card style={{minWidth: 250, height: 380}}>
                    <div style={{width: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", height: 10}}>
                        <MinusIcon style={{width: 20, height: 30, padding: 0, margin: 0, marginTop: -20, cursor: "pointer"}}/>
                    </div>
                    <Divider style={{width: "100%", margin: 0}} />
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <h3>Incoming call</h3>
                        <img src={img} style={{borderRadius: "50%", width: 100}} alt="caller"/>
                        <p>{source} to {target}</p>
                        <Tag color="magenta">{category}</Tag>
                        <div className="waveform-elements">
                            <div className="waveform-bar">
                            </div>
                        </div>
                    </div>
                    <div style={{display:"flex", position: "relative", alignItems: "center", justifyContent: "center", marginTop: 30}}>
                        {!record && <div className={"call-animation"} onClick={()=>setRecord(true)}><Button shape="circle" style={{width: 50, height: 50, display: "flex", justifyContent: "center", alignItems: "center"}} icon={<LocalPhoneIcon />} /></div>}
                        <div onClick={()=>setRecord(false)}><Button className={record ? "end-call-recording" : "end-call"} shape="circle" icon={<CallEndIcon />} /></div>
                    </div>
                </Card>
    )
}