import "./MessageBox.css";
import Button from '@mui/material/Button';

export default function MessageBox({title, message, showButton, action,}) {

    return(
        <div className="message-box">
            <h3>{title}</h3>
            <p>{message}</p>
            { showButton &&
            <div className="centered-button">
                <Button variant="contained" onClick={action}>Continue to test</Button>
            </div>
            }
        </div>
    )
}