import {Tabs} from 'antd';
import Statistics from './partials/Statistics.jsx';
import Data from './partials/Data.jsx';

export default function AnalyticsContainer() {

    const tabs = [
        {
            key: 0,
            children: <Statistics />,
            label: "Statistics"
        },
        {
            key: 1,
            children: <Data />,
            label: "Data tables"
        }
    ]



    return (
        <div className="section-container">
            <div>
                <Tabs 
                    items={tabs}
                />
                
            </div>
        </div>

    )
}
