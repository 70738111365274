import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HomeIcon from '@mui/icons-material/Home';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import InsightsIcon from '@mui/icons-material/Insights';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined';
import AccountTreeRoundedIcon from '@mui/icons-material/AccountTreeRounded';
import { atom } from 'jotai';

export const highlightedOptions = atom([]);

export const personalPicture = atom("");

export const candidateOptions = [
    {
        title: "Home",
        icon: <HomeIcon />,
        path: "/",
        translateY: "12.8px",
        backgroundColor: "#FD3A69"
    },
    {
        title: "Profile",
        icon: <AccountCircleIcon/>,
        path: "profile",
        translateY: "52.8px",
        backgroundColor: "#FFE227"
    },
    {
        title: "Tests",
        icon: <DescriptionOutlinedIcon/>,
        path: "tests",
        translateY: "92.8px",
        backgroundColor: "#28FFBF"
    },
]

export const adminOptions = [
    {
        title: "Analytics",
        icon: <InsightsIcon />,
        path: "analytics",
        translateY: "12.8px",
        backgroundColor: "#FD3A69"
    },
    {
        title: "Tests",
        icon: <DescriptionOutlinedIcon/>,
        path: "tests",
        translateY: "52.8px",
        backgroundColor: "#28FFBF"
    },
    {
        title: "Blueprints",
        icon: <AccountTreeRoundedIcon/>,
        path: "blueprints",
        translateY: "92.8px",
        backgroundColor: "#FFE227"
    },
    {
        title: "Manage users",
        icon: <PeopleOutlineOutlinedIcon/>,
        path: "manage",
        translateY: "132.8px",
        backgroundColor: "#8E44AD"
    },
    {
        title: "Documentation Hub",
        icon: <WorkspacesOutlinedIcon/>,
        path: "documentation",
        translateY: "172.8px",
        backgroundColor: "#F39C12",
        submenu: true
    },
]
