import { useState } from "react"
import {MainButton, SecondaryButton, DangerButton, OutlineButton, LoadingButton, SystemButton, PlainButton} from "./Buttons.jsx"
import "./UI.css"

export default function UI() {

    const [clicked, setClicked] = useState(false)

    const handleClick = () => {
        setClicked(!clicked)
    }

    return (
    <div className="section-container">
        <h1>UI Cheatsheet</h1>
        <div className="ui-items">
            <h2>Buttons</h2>
            <MainButton text="New blueprint"/>
            <SecondaryButton text="Cancel export" />
            <DangerButton text="Delete profile" />
            <OutlineButton text="Add"/>
            <LoadingButton text="Exporting files" type="main"/>
            {
                clicked ?
                <div onClick={handleClick}><LoadingButton text="Login" type="system"/></div>
                :
                <div onClick={handleClick}><SystemButton text="Login" onClick={handleClick}/></div>
            }
            <div onClick={handleClick}><PlainButton text="Disable user" loading={clicked}/></div>
            
        </div>
    </div>
    )
}