import {Card, Tag} from "antd"
import { useParams, useNavigate } from "react-router-dom"
import { useState, useEffect } from "react";
import Editor from "../Items/Editor";
import { getRequestAuth } from "../../Tools/Requests";
import { useAtom } from "jotai";
import { userAtom } from "../../Contexts/Atoms";
import { putRequest } from "../../Tools/Requests";
import DocumentTools from "./Partials/DocumentTools";
import Title from "./Partials/Title";

export default function DocumentView() {

    const [document, setDocument] = useState({})
    const {document_id} = useParams();
    const [currentUser] = useAtom(userAtom)
    const [readOnly, setReadOnly] = useState(true)
    const [updatedData, setUpdatedData] = useState({})
    const [categories, setCategories] = useState([])
    const [categoryName, setCategoryName] = useState("")

    const navigate = useNavigate();

    const getCats = () => {
        getRequestAuth("/documents/categories")
        .then((res)=> {
            setCategories(res.categories);
        })
    }

    const getDocument = () => {
        getRequestAuth(`/documents/view/${document_id}`)
        .then((res)=> {
            if(res.success) {
                if (res.document?.id === undefined) {
                    navigate("/documentation")
                    return;
                }
                setDocument(res.document)
            }
        })
    }    

    const postNew = () => {
    
        if (document.title.length === 0) {
            return;
        } else if (document.specs.category === 0) {
            return;
        } else if (document.specs.type.length === 0) {
            return;
        }
       
        const body = {
            title: document.title,
            editorData: updatedData,
            category: document.specs.category,
            type: document.specs.type,
            hide: document.specs.hide,
            lock: document.specs.lock,
            trash: document.specs.trash
        }
        
        return putRequest("/documents/view/"+document_id, body)
        .then((res)=> {
            return res;
        })
  
    }

    useEffect(()=>{
        getDocument()
    }, [])

    useEffect(()=>{
        getDocument()
        setReadOnly(true)
        getCats()
    }, [document_id])

    useEffect(()=> {
        setCategoryName(categories.filter((c)=>c.id === document?.specs?.category)[0]?.name)
    }, [categories, document_id])

    return (
        <div className="section-container">
            <Card
                style={{width: "70vw"}}
                title={
                <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap"}}>
                    <div>
                        <Title 
                            readOnly={readOnly}
                            documentTitle={document.title}
                            setter={setDocument}
                        />
                        <DocumentTools
                            postNew={postNew}
                            document={document}
                            setDocument={setDocument}
                            readOnly={readOnly}
                            setReadOnly={setReadOnly}
                            currentUser={currentUser}
                            updatedData={updatedData}
                        />
                    </div>
                    <div style={{fontWeight: 400, fontSize: "small", display: "flex", flexDirection: "column", gap: 10}}>
                      <div>Category: <Tag color="purple">{categoryName}</Tag></div>
                      <div>Type: <Tag color="cyan">{document?.specs?.type}</Tag></div>  
                    </div>
                </div>
                }
            >
                {
                    document.editorData &&
                    <div style={{marginLeft: (readOnly ? -180 : -120), marginTop: 10,}}>
                        <Editor
                            key={document.id} 
                            editorData={document.editorData}
                            readOnly={readOnly}
                            setEditorData={setUpdatedData}
                            placeholder={"This document is empty."}
                        />
                    </div>
                }
            </Card>
        </div>
    )
}