import "./BasicCardContainer.css";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";
import { postRequest } from "../../Tools/Requests";
import SearchBar from "../Items/SearchBar.jsx";
import AdminTests from "./AdminTests.jsx";
import UserTests from "./UserTests.jsx";


export default function BasicCardContainer () {
    
    const [assessments, setAssessments] = useState([])
    const [filteredAssessments, setFilteredAssessments] = useState([])
    const [admin, setAdmin] = useState(false)
    
    useEffect(()=> {
        postRequest("/auth/access")
        .then(async (res)=> {
            res.user.identity.account_type === "Admin" && setAdmin(true)
        })
    }, [])

    useEffect(()=> {
        setFilteredAssessments(assessments)
    }, [assessments])

    const filterTests = (term) => {
        const termL = term.toLowerCase();

        const filteredTests = assessments.filter((a) =>
          a.title?.toLowerCase().includes(termL)
          ||
          a.category?.toLowerCase().includes(termL)
          ||
          a.language?.source?.toLowerCase().includes(termL)
          ||
          a.language?.target?.toLowerCase().includes(termL)
          ||
          a.tags?.includes(termL)
        );
       
        termL.length === 0 ? setFilteredAssessments(assessments) : setFilteredAssessments(filteredTests);
    };

    return (
        <div className="section-container">    
            <div className="search-container">
                { admin && 
                    <Link to="create">
                    <Button
                    style={{backgroundColor: "#4FBBB7", color: "white", fontWeight: 600}}
                    >New test</Button>
                </Link>
                }
                <SearchBar 
                    searchHandler={filterTests}
                    placeholder={"Search tests"}
                />
            </div>
            <div className="box-list">
                {!admin &&
                    <UserTests 
                    setAssessments={setAssessments}
                    assessments={filteredAssessments}
                    />
                }
            </div>
                {admin && 
                    <AdminTests 
                    setAssessments={setAssessments}
                    assessments={filteredAssessments}        
                    />
                }
        </div>
    )
}