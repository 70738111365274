import "./Button.css";
import { ReloadIcon } from "@radix-ui/react-icons"

export function MainButton({text}) {

    return(
    <div className="main-button button-inner">
        <p>{text}</p>
    </div>
    )
}

export function SecondaryButton({text}) {
    return (
        <div className="secondary-button button-inner">
            <p>{text}</p>
        </div>
    )
}

export function DangerButton({text}) {
    return (
    <div className="danger-button button-inner">
        <p>{text}</p>
    </div>
    )
}

export function OutlineButton({text}) {
    return (
        <div className="outline-button button-inner">
            <p>{text}</p>
        </div>
    )
}

export function LoadingButton({text, type}) {
    return (
        <div className={`${type}-button button-inner loading-button`}>
            <ReloadIcon className="loading-icon"/>
            <p>{text}</p>
        </div>
    )
}

export function SystemButton({text}) {
    return (
        <div className="system-button button-inner">
            <p>{text}</p>
        </div>
    )
}

export function PlainButton({text, loading}) {
    return (
        <div className="plain-button button-inner">
            {loading && <ReloadIcon className="loading-icon" />}
            <p>{text}</p>
        </div>
    )
}