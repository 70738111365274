import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { Cross2Icon } from "@radix-ui/react-icons";

/**
 *
 * @param {String} type - error, warning, info, or success type of alert
 * @param {String} message - message to be displayed
 *
 */

export default function BasicAlerts({type, message, closeHandler}) {
  return (  
    <Stack sx={{ width: '100%' }} spacing={2}>
      <Alert severity={type}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between"}}>{message} <Cross2Icon style={{cursor: "pointer"}} onClick={closeHandler}/></div>
      </Alert>
    </Stack>
  );
}