import { ChevronDownIcon } from "@radix-ui/react-icons"
import { useState } from "react"
import "./ProfileContainer.css"
import { useParams } from "react-router-dom"
import { PlainButton } from "../Items/Buttons"
import { deleteRequest, putRequest } from "../../Tools/Requests"
import BasicAlerts from "../Items/Alerts";
import { useNavigate } from "react-router-dom"
import { Tooltip } from "@mui/material"
import {Card, Modal} from 'antd';

export default function AdvancedOptions({user, updateCall}) {

    const navigate = useNavigate()

    const [options, setOptions] = useState(
        {
            account: false,
            email: false,
            access: false
        }
    )
    
    const [alert, setAlert] = useState({})

    const [disabling, setDisabling] = useState(false)

    const { user_id } = useParams()

    // Sets status to one of "enabled", "disabled" or deletes the record
    const updateStatus = (status)=> {

        setDisabling(true)

        if (status === "delete") {
            deleteRequest("/interpreter_data/delete", {
                userId: user_id,
                accountType: user.identity.account_type
            })
            .then((res)=> {
                setAlert({
                    status: true,
                    type: "success",
                    message: "User deleted."
                })
    
                navigate("/manage")
            })
            setDisabling(false)
            setOpenModal(false)
            return;
        } else {        
            putRequest(`/interpreter_data/update_organization_status`, {
                userId: user_id,
                status: status,
                accountType: user.identity.account_type
            })  
            .then((res)=>{
                setAlert({
                    status: true,
                    type: "success",
                    message: `User status changed to ${status}.`
                })
                setDisabling(false)
                updateCall({...user, organization: {...user.organization, status: status}})            
            })
        }

      }

    const [openModal, setOpenModal] = useState(false)

    return (
       <Card
        title={<h3 style={{padding:0, margin:0}}>Advanced options</h3>}
        style={{width: 500, marginTop: 20}}
        size="small"
       > 
            <Modal
                open={openModal}
                onCancel={()=>setOpenModal(false)}
                onOk={()=>updateStatus("delete")}
                okButtonProps={{ style: { backgroundColor: 'red' } }}
                okText="Confirm deletion" 
            >
                <>
                    <p>
                        Deleting a user from your database will have an
                        impact in the following items
                    </p>
                    <ul>
                        <li>User record data.</li>
                        <li>User submissions.</li>
                        <li>User data related to tests.</li>
                        <li>User notes.</li>
                    </ul>
                    <p>Deleted data cannot be retrieved.</p>
                </>
            </Modal>
            <div className="tree-container">
                <div>
                    <div onClick={()=> {setOptions({...options, account: !options.account})}} className="tree-title">
                        <p>Account</p><ChevronDownIcon className={!options.account ? "tree-chevron" : "tree-chevron tree-chevron-open"}/>
                    </div>
                    <div className={!options.account ? "tree-content" : "tree-content-open tree-content"}>
                        {user?.organization?.status === "enabled" && <Tooltip title="Disabled users no longer have access to your assessments"><div onClick={()=> updateStatus("disabled")} style={{color: "red"}}><PlainButton text="Disable user" loading={disabling} /></div></Tooltip>}
                        {user?.organization?.status === "disabled" && <div onClick={()=>{setOpenModal(true)}} style={{color: "red"}}><PlainButton text="Delete user" loading={disabling} /></div>}
                        {user?.organization?.status === "invited" && <div onClick={()=>{setOpenModal(true)}} style={{color: "red"}}><PlainButton text="Delete user" loading={disabling} /></div>}
                        {user?.organization?.status === "disabled" && <div onClick={()=> updateStatus("enabled")} style={{color: "blue"}}><PlainButton text="Re-enable user" loading={disabling} /></div>}
                        {alert.status && <BasicAlerts type={alert.type} message={alert.message} closeHandler={()=>setAlert({...alert, status:false})}/>}
                    </div>
                </div>
                <div>
                    <div onClick={()=> {setOptions({...options, email: !options.email})}} className="tree-title">
                        <p>Email</p><ChevronDownIcon className={!options.email ? "tree-chevron" : "tree-chevron tree-chevron-open"}/>
                    </div>
                    <div className={!options.email ? "tree-content" : "tree-content-open tree-content"}>
                    <div style={{color: "blue"}}><PlainButton text="Send password reset email" loading={disabling} /></div>
                    </div>
                </div>
            </div>
        </Card>
    )
}