import { useParams, Link } from "react-router-dom"
import {Card, List, Button} from "antd";
import { useEffect, useState } from "react";
import { getRequestAuth } from "../../Tools/Requests";

export default function CategoryView() {

    const {category_id} = useParams(); 

    const [docs, setDocs] = useState([]);
    const [catName, setCatName] = useState("")

    useEffect(()=> {
        getRequestAuth("/documents/get_documents_category/"+category_id)
        .then((res)=> {
            if (res.success) {
                setDocs(res.docs)
            }
        })

        getRequestAuth("/documents/categories")
        .then((res)=> {
            if (res.success) {
                const filtered = res.categories.filter((c)=>c.id === Number(category_id))
                setCatName(filtered[0].name)
            }
        })
    }, [category_id])

    return (
        <div className="section-container">
            <Card 
            title={<div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <h2>Category | {catName}</h2>
                        <Link to="/documentation/create"><Button
                            style={{backgroundColor: "#4FBBB7", color: "white", fontWeight: 600}}
                        >New document</Button></Link>
                    </div>}
            style={{backgroundColor: "white"}}
            >
                <List
                    style={{backgroundColor: "white", width: 700}}
                    size="small"
                    bordered
                    dataSource={docs}
                    pagination={{
                        pageSize: 20,
                        }}
                    renderItem={(doc) => (
                        <List.Item>
                            <div style={{display: "flex", flexDirection: "column"}}>
                                <span style={{fontWeight: 500, fontSize: "small"}}>{doc.specs.type} | {doc.date.date}</span>
                                <Link to={`/documents/${doc.id}`}>{doc.title}</Link>
                            </div>
                        </List.Item>
                    )}
                />
            </Card>
        </div>
    )
}