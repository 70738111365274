import Editor from "../Items/Editor";
import { Card, Select, Input, Modal, Checkbox, Button, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { GearIcon } from "@radix-ui/react-icons";
import { getRequestAuth, postRequest } from "../../Tools/Requests";
import { useNavigate } from "react-router-dom";

export default function NewDocument() {

    const [title, setTitle] = useState("New document");
    const [openSettings, setOpenSettings] = useState(false);

    const [sendingDocument, setSendingDocument] = useState(false)
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const [editorData, setEditorData] = useState({});

    const [categories, setCategories] = useState([])
    const [hide, setHide] = useState(false)
    const [lock, setLock] = useState(false)
    const [selectedCat, setSelectedCat] = useState(0)
    const [selectedType, setSelectedType] = useState("")

    const navigate = useNavigate();

    const getCats = () => {
        getRequestAuth("/documents/categories")
        .then((res)=> {
            setCategories(res.categories.map((c)=> ({value: c.id, label: c.name})));
        })
    }

    const postNew = () => {
    
        if (title.length === 0) {
            return;
        } else if (selectedCat === 0) {
            return;
        } else if (selectedType.length === 0) {
            return;
        }
        
        setSendingDocument(true)
        const body = {
            title: title,
            editorData: editorData,
            category: selectedCat,
            type: selectedType,
            hide: hide,
            lock: lock
        }
        
        postRequest("/documents/new", body)
        .then((res) => {
            setSendingDocument(false)
            navigate("/documentation")
        })     
    
    }

    useEffect(()=> {
        if ((title.length === 0 )|| (selectedCat === 0) || (selectedType.length === 0)) {
            setButtonDisabled(true)
        } else {
            setButtonDisabled(false)
        }

    }, [title, selectedCat, selectedType])

    useEffect(()=> {
        getCats()
    }, [])

    return (
        <div className="section-container">
            <Card style={{width: "70vw"}}
            title={
            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                <h2>{title}</h2>
                <div style={{display: "flex", gap: 20, alignItems: "center"}}>
                    <GearIcon height="20" width="20" style={{cursor: "pointer"}} onClick={()=> setOpenSettings(true)}/>
                    <Tooltip title={buttonDisabled ? "You must enter a title, category and document type in order to save a new document." : null}>
                        <Button
                                onClick={postNew}
                                disabled={buttonDisabled}
                                loading={sendingDocument}
                                style={{backgroundColor: "#4FBBB7", color: "white", fontWeight: 600}}
                                >Save document
                        </Button>
                    </Tooltip>
                    <Modal 
                        open={openSettings}
                        onOk={()=> setOpenSettings(false)}
                        onCancel={()=> setOpenSettings(false)}
                    >
                        <div style={{display: "flex", flexDirection: "column", gap: 20}}>
                            <div>
                                <p style={{padding: 0, margin: 0}}>Document type</p>
                                <Select 
                                    placeholder="Document type"
                                    style={{width: 170}}
                                    options={[{value: "Article", label: "Article"}, {value: "FAQ", label: "FAQ"}, {value: "Terms", label: "Terms and Conditions"}]}
                                    onChange={(value)=> setSelectedType(value)}
                                />
                            </div>
                            <div>
                                <p style={{padding: 0, margin: 0}}>Category</p>
                                <Select 
                                    placeholder="Select a category"
                                    style={{width: 170}}
                                    options={categories}
                                    onChange={(value)=> setSelectedCat(value)}
                                />
                            </div>
                            <div style={{display: "flex", flexDirection: "column"}}>
                                <Checkbox onChange={(e)=>setHide(e.target.checked)}>Hide</Checkbox>
                                <Checkbox  onChange={(e)=>setLock(e.target.checked)}>Lock</Checkbox>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
            }
            >
                <Input 
                bordered={false}
                onChange={(e)=>setTitle(e.target.value)}
                placeholder="Document title"
                height={100}
                style={{fontSize: 25}}
                />
                <div style={{marginLeft: -120, marginTop: 10}}>
                    <Editor 
                        editorData={editorData}
                        setEditorData={setEditorData}
                        readOnly={false}
                    />
                </div>
            </Card>
        </div>
    )
}