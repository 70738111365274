import "./TopBar.css"
import Avatar from '@mui/material/Avatar';
import profilePicture from "../../../Assets/profile-picture.webp";
import { useState, useEffect } from "react";
import { ChevronDownIcon, PersonIcon, GearIcon, ExitIcon } from "@radix-ui/react-icons";
import { Link } from "react-router-dom";
import { getRequestAuth } from "../../../Tools/Requests";
import {Dropdown, Divider} from 'antd';
import Search from "./options/Search";
import SearchModal from "../Search/SearchModal";
import { personalPicture } from "./options/options";
import { useAtom } from "jotai";

export default function TopBar({loggedIn}) {

    const [userData, setUserData] = useState({})
    const [picture, setPicture] = useAtom(personalPicture)
    const [displayMenu, setDisplayMenu] = useState(false)

    const [terms, setTerms] = useState("")
    const [lastSearch, setLastSearch] = useState("")
    const [openSearch, setOpenSearch] = useState(false)
    const [searchResults, setSearchResults] = useState([])

    useEffect(()=> {
        if(loggedIn) {
            getRequestAuth("/prl/profile_data")
            .then(async (res)=> {
            setUserData(res)
            setPicture(res.profile_assets?.profile_picture)
        })
        }
    }, [loggedIn])

    const logout = ()=> {
        window.location = "/login"
        localStorage.removeItem("token")
    }  

    const items = [
        {
            key: "1",
            label: (<Link to="/profile" className="top-bar-option"><PersonIcon/><li>Profile</li></Link>)
        },
        {
            key: "2",
            label: (<Link to="/settings" className="top-bar-option"><GearIcon /><li>Settings</li></Link>)
        },
        {
            key: "3",
            label: (
                <>
                <Divider style={{padding: 0, margin: 0}}/>
                <div className="top-bar-logout"><ExitIcon /><li onClick={logout}>Log out</li></div>
                </>
                )
        }
    ]

    const handleSearch = ()=> {
        if (terms.length > 0) {
            setOpenSearch(true)

            if (lastSearch === terms) {
                return;
            }

            getRequestAuth("/search/terms?terms="+terms)
            .then((res)=> {
                if (res.success) {
                    setSearchResults(res.results)
                    setLastSearch(terms)
                }
            })
        }
    }

    return (
        <div className="top-bar-container">
            <Search handleSearch={handleSearch} handleChange={setTerms}/>
            <SearchModal 
                open={openSearch}
                handleCancel={setOpenSearch}
                loading={true}
                results={searchResults}
                term={terms}
            />
            <div onMouseEnter={()=>setDisplayMenu(true)} onMouseLeave={()=>setDisplayMenu(false)}>
                <Dropdown menu={{items}}>
                    <div className="top-bar-inner">
                        <Avatar sx={{height: "35px;", width: "35px"}} alt="User profile picture" src={picture ? picture : profilePicture} />
                        <p>{userData?.identity?.first_name} {userData?.identity?.last_name}</p>
                        <ChevronDownIcon className={displayMenu ? "transition rotate" : "transition"}/>
                    </div>
                </Dropdown>
            </div>
        </div>
    )
}