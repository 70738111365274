import {SwifttBar, SwifttPie, Stat, SwifttLine, SwifttProgress} from "../../Items/data_visualization/SwifttCharts.jsx";

export default function Statistics() {
    return (
        <div>
            <div style={{display:"flex", flexWrap: "wrap", gap: 10}}>
                <SwifttBar title="Submission results" />
                <SwifttPie title="Submission status" />
                <div style={{display:"flex", flexWrap: "wrap", gap: 10}}>
                    <Stat stat={32} title="Pending submissions"/>
                    <Stat stat={254} title="Active interpreters"/>
                    <Stat stat={21} title="Invited interpreters"/>
                    <SwifttProgress title="331 transcription minutes remaining"/>
                </div>
                <SwifttLine title="Recent submissions" />
            </div>
        </div>
    )
}