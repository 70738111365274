import {Modal, List} from 'antd';
import { useNavigate } from 'react-router-dom';

export default function SearchModal({open, term, handleCancel, results}) {

    const navigate = useNavigate();
    const handleNavigate = (id)=> {
        handleCancel(false)
        navigate("/documents/"+id)
    }
    
    return (
        <Modal
            open={open}
            footer={null}
            onCancel={()=>handleCancel(false)}
            title={<h3>Search results for: {term}</h3>}
            width={"50vw"}
        >
            <List
            style={{backgroundColor: "white", width: 700, width: "45vw"}}
                    size="small"
                 
                    dataSource={results}
                    renderItem={(result) => (
                        <List.Item>
                            <div className="top-navbar-search-link" onClick={()=> handleNavigate(result.id)}>
                                <div style={{display: "flex", flexDirection: "column"}}>
                                    <span style={{fontWeight: 500}}>{result.title} | {result.specs.type}</span>
                                    <span>Matching score: {Math.round(result.score * 100) / 100}</span>
                                    <p className="top-navbar-search-result">{result.highlights.slice(0, 400)}</p>
                                </div>
                            </div>
                        </List.Item>
                    )}
            />
        </Modal>
    )
}