import "./Login.css";
import { Link } from 'react-router-dom';
import { useState } from "react";
import BasicAlerts from "../Items/Alerts";
import { postRequest } from "../../Tools/Requests";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import {Button, Input} from 'antd';

export default function Login() {

    const [logInProcess, setLogInProcess] = useState(false)

    const [loginEmail, setLoginEmail] = useState("");
    const [loginPassword, setLoginPassword] = useState("");

    const [wrongAuth, setWrongAuth] = useState(false)

    const loginUser = () => {
        setLogInProcess(true)
        postRequest("/auth/login", { 
            username: loginEmail,
            password: loginPassword 
        })
        .then( async (res)=>{
            if (res.error) {
                setWrongAuth(true)
                setLogInProcess(false)
                return
            } 
            
            setLogInProcess(false)  
            localStorage.setItem('token', res.token)
            window.location = "/"
            
        })
    }

    return (

        <div className="login-background d-flex-column">
            <div className="login-form-container">
                <div className="login-presentation">
                    <div className="login-presentation-text">
                        <h1 className="logo-name">Swiftt</h1>
                        <p>The leading next-generation interpreter screening & management platform</p>
                    </div>
                </div>
                <div className="login-form-fields">
                    <h2 style={{margin: 0}}>Login</h2>
                    {
                        wrongAuth &&
                        <BasicAlerts 
                            sx={{width: 100}}
                            type="error"
                            message="Wrong username or password."
                            closeHandler={()=>{setWrongAuth(false)}}
                            />
                    }
                    <Input placeholder="Email address" onChange={(e)=> setLoginEmail(e.target.value)} onPressEnter={loginUser}/>
                    <Input.Password
                        placeholder="Password"
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        onChange={(e)=>setLoginPassword(e.target.value)}
                        onPressEnter={loginUser}
                    />
                    <Button
                        loading={logInProcess}
                        onClick={loginUser}
                        >Login</Button>
                    <Link to="/reset-password"><p>Forgot password?</p></Link>
                </div>
            </div>
        </div>
    )
}