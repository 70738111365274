import { Link } from "react-router-dom";
import "./Subitem.css";

export default function Subitem({isSelected, option, onSelect}) {

    return (
            <Link to={"documentation/category/"+option.id} 
                  className={isSelected === option.id && "subitem-selected"}
                  onClick={()=>onSelect(option.id)}
                  style={{padding: "4px 0"}}
            >
                <li>{option.name}</li>
            </Link>
    )

}