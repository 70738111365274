import TestEditor from "./TestEditor.jsx"
import { useEffect, useState } from "react"
import { useNavigate} from "react-router-dom";
import { getRequestAuth, postRequest } from "../../Tools/Requests.js";

export default function TestUpdaterContainer() {

    const navigate = useNavigate()

    const [languages, setLanguages] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(()=> {

        getRequestAuth("/asmts/languages")
        .then((res)=>{
            setLanguages(res)
        })

    }, [])


    const sendInfo = (content)=> {
        setLoading(true)
        postRequest("/asmts/post-new", content)
        .then((res)=>{
            if (res.success) {
                setLoading(false)
                navigate("/tests")
                
            }  
        })
    }
    

    return(
        <div className="section-container">
            <TestEditor tlanguages={languages} sendInfo={sendInfo} loading={loading} buttonLegend="Save new test"/>
        </div>
    )
}