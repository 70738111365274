import "./InterpreterManager.css"
import { Tabs } from "antd"
import InviteUsers from "./InviteUsers"
import ManageUsers from "./ManageUsers"

export default function InterpreterManager() {
    const tabs = [
        {
            key: 0,
            children: <ManageUsers />,
            label: "Users"
        },
        {
            key: 1,
            children: <InviteUsers />,
            label: "Invite"
        }
    ]

    return (
        <div className="section-container">
            <div>
                <Tabs 
                    items={tabs}
                />
            </div>
        </div>
    )
}