import EditingCommands from "./EditingCommands";
import {Button} from 'antd';
import OptionsPresentation from "./OptionsPresentation";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { deleteRequest, putRequest } from "../../../Tools/Requests";
import { useState } from "react";

export default function DocumentTools({postNew, document, setDocument, readOnly, setReadOnly, currentUser, updatedData}) {

    const location = useLocation().pathname.split("/")[1];
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [restoreLoading, setRestoreLoading] = useState(false)

    const {document_id} = useParams();
    const navigate = useNavigate();

    const deleteDocument = ()=> {
        setDeleteLoading(true)
        deleteRequest("/documents/view/"+document_id)
        .then((res)=> {
            if (res.success) {
                setDeleteLoading(false)
                navigate("/documentation")
            }
        })

    }

    const restoreDocument = ()=> {
        setRestoreLoading(true)
        putRequest("documents/view/restore/"+document_id)
        .then((res)=> {
            if (res.success) {
                setRestoreLoading(false)
                navigate("/documentation")
            }
        })
    }

    return (
        <div>
        {
            location === "trash" ?

            <div style={{display: "flex", gap: 10}}>
                <Button loading={restoreLoading} onClick={restoreDocument}>Restore</Button>
                <Button danger loading={deleteLoading} onClick={deleteDocument}>Delete permanently</Button>
            </div>
            :
            (readOnly ?
            <OptionsPresentation 
                lock={document?.specs?.lock}
                currentUserId={currentUser.id}
                authorId={document.author_id}
                setReadOnly={setReadOnly}
            />
            :
            <EditingCommands 
                postNew={postNew}
                document={document}
                setter={setDocument}
                updatedData={updatedData}
            />)
        }
        </div>
    )
}