export const interpreters = {
    dataset: "Interpreters",
    columns: [
    {
        designation: "interpreter_id",
        display: "Interpreter ID",
    },    
    {
        designation: "first_name",
        display: "Interpreter name",
    },
    {
        designation: "last_name",
        display: "Last name",
    },
    {
        designation: "primary_email",
        display: "Primary email",
    },
    {
        designation: "secondary_email",
        display: "Secondary email",
    },
    {
        designation: "account_type",
        display: "Account type",
    },
    {
        designation: "phone_number",
        display: "Phone number",
    },
    {
        designation: "address",
        display: "Address",
    },
    {
        designation: "city",
        display: "City",
    },
    {
        designation: "country",
        display: "Country",
    },
    {
        designation: "time_zone",
        display: "Time zone",
    },
]
}

export const submissions = {
    dataset: "Submissions",
    columns: [
    {   
        designation: "submission_id",
        display: "Submission ID",
    },
    {   
        designation: "creation_date",
        display: "Creation date",
    },
    {   
        designation: "interpreter_name",
        display: "Interpreter name",
    },
    {   
        designation: "interpreter_email",
        display: "Interpreter email",
    },
    {   
        designation: "status",
        display: "Status",
    },
    {   
        designation: "comment",
        display: "Comment",
    },
    {   
        designation: "score",
        display: "Score",
    },
    {
        designation: "duration",
        display: "Duration",
    },
    {
        designation: "test_id",
        display: "Test ID",
    },

]
}

export const tests = {
    dataset: "Tests",
    columns: [
    {   
        designation: "creation_date",
        display: "Creation date",
    },
    {   
        designation: "title",
        display: "Title",
    },
    {   
        designation: "source_language",
        display: "Source language",
    },
    {   
        designation: "target_language",
        display: "Target language",
    },
    {   
        designation: "category",
        display: "Category",
    },
    {   
        designation: "tags",
        display: "Tags",
    },
    {   
        designation: "expiration_date",
        display: "Expiration date",
    },
    {   
        designation: "total_assigned",
        display: "Number of assignees",
    },
    {   
        designation: "allowed_submissions",
        display: "Allowed submissions",
    },
    {   
        designation: "status",
        display: "Status",
    },
    {   
        designation: "completion_certificate",
        display: "Completion certificate",
    },
    {   
        designation: "test_id",
        display: "Test ID",
    },

]
}