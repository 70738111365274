import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Tooltip } from "@mui/material";
import { Button } from 'antd';

export default function OptionsPresentation({lock, currentUserId, authorId, setReadOnly}) {
    return (
        <div style={{marginBottom: 14}}>
        {
        lock ?
            <div style={{display: "flex", alignItems: "center", gap: 10}}>
                {currentUserId === authorId && <Button onClick={()=>{setReadOnly(false)}}>Edit</Button>}
                <Tooltip title="Editing was locked by the document owner."><LockIcon style={{fontSize: 20}}/></Tooltip>
            </div>
            :
            <div style={{display: "flex", alignItems: "center", gap: 10}}>
                <Button onClick={()=>{setReadOnly(false)}}>Edit</Button>
                <Tooltip title="Editing is unlocked."><LockOpenIcon style={{fontSize: 20}}/></Tooltip>
            </div>
        }
        </div>
    )
}