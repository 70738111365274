import { useEffect, useRef, useState, Fragment } from 'react';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import Table from '@editorjs/table';
import ImageTool from '@editorjs/image';
import CheckList from '@editorjs/checklist';
import {postRequestFormData} from '../../Tools/Requests.js' 

const Editor = ({editorData, setEditorData, readOnly, placeholder}) => {
  const ejInstance = useRef();
  const [divHolderid, setDivHolderId] = useState(`editor-${Math.random().toString(36)}`)

  // This will run only once
  useEffect(() => {
    if (!ejInstance.current) {
      initEditor();
    }
    return () => {
        if (ejInstance.current){
        ejInstance.current.destroy();
        ejInstance.current = null;}
    }
  }, []);

  useEffect(()=> {
    if (ejInstance.current){
    ejInstance.current.readOnly.toggle(readOnly)
    }
  }, [readOnly])

  const uploadByFile = (file) => {
        // Implement your custom upload logic for files here
        return new Promise((resolve, reject) => {
            // Example: Upload file to your backend server
            postRequestFormData("/interpreter_data/new_note_image", file, "image")
            .then((res)=> {
                resolve(res)
            })  
        }
        )
    }

  const initEditor = () => {
    
    const editor = new EditorJS({
      holder: divHolderid,
      logLevel: "ERROR",
      data: editorData,
      readyOnly: readOnly,
      onReady: () => {
        ejInstance.current = editor;
        ejInstance.current.readOnly.toggle(readOnly)
      },
      placeholder: placeholder,
      onChange: async () => {
        editor.save().then((outputData) => {
            setEditorData(outputData)
          }).catch((error) => {
            console.log('Saving failed: ', error)
          });
      },
      autofocus: true,
      tools: { 
        header: Header, 
        list: List,
        table: {class: Table, inlineToolbar: true, withHeadings: true},
        checklist: CheckList,
        image: {
            class: ImageTool,
            config: {
              uploader: {uploadByFile},
              types: "image/*"
            }
          }
      }, 
    });
   
  };
 
  return (
    <Fragment>
      <div id={divHolderid}> </div>
    </Fragment>
  );
}
 
export default Editor;