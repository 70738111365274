import { useEffect, useState } from "react";
import { useSearchParams } from 'react-router-dom';
import ResetRequest from "./ResetRequest";
import SetNewPassword from "./SetNewPassword";

export default function ResetPassword() {
    
    const [isToken, setIsToken] = useState(false)
    const [urlToken, setUrlToken] = useState("")
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(()=> {
        const token = searchParams.get("token")
    
        if (token) {
            setIsToken(true)
            setUrlToken(token)
        }

    }, [])    
    

    return (
        <div className="login-background d-flex-column">
            <div className="login-form-container">
                {
                 isToken ? 
                    <SetNewPassword token={urlToken}/> 
                    :
                    <ResetRequest/>
                    }
                    
            </div>
        </div>
    )
}