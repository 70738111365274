import { useEffect, useState } from "react"
import { postRequest } from "../../Tools/Requests"
import { useNavigate } from "react-router-dom";
import {Input, Button} from 'antd';

export default function SetNewPassword({token}) {

    const [newPassword, setNewPassword] = useState("")
    const [repeatPassword, setRepeatPassword] = useState("")
    const [load, setLoad] = useState(false)
    const [match, setMatch] = useState([false, false])

    const navigate = useNavigate()

    useEffect(() => {
      if (newPassword.length > 0 && repeatPassword.length > 0) {
        if (newPassword === repeatPassword) {
          setMatch([true, false]);
        } else {
          setMatch([false, true]);
        }
      } else {
        setMatch([false, false]);
      }
    }, [newPassword, repeatPassword]);

    const handleReset = () => {
      if (!match[0]) {
        return;
      }

      setLoad(true);
      postRequest("/accounts/reset_password", {
        token: token,
        password: newPassword,
      }).then(() => {
        setLoad(false);
        navigate("/login");
      });
    };

    return (
        <>
            <div className="login-presentation" style={{marginBottom: 20}}>
                <div className="login-presentation-text">
                    <h2>Reset Password</h2>
                    <p>Glad to see you here again. You may now enter a new password</p>
                </div>
            </div>
                <div className="login-form-fields">
                
                    <Input
                    placeholder="New password"
                    onChange={(event)=> setNewPassword(event.target.value)}
                    />
                    <Input
                    placeholder="Repeat password"
                    onChange={(event)=> setRepeatPassword(event.target.value)}
                    />
           
                <div className="login-button-container">
                {
                    match[0] && <div className={"pill-match pill-match-green"}><p>Passwords match!</p></div>
                }
                {
                    match[1] && <div className={"pill-match pill-match-red"}><p >Passwords don't match!</p></div>
                }
                <Button
                loading={load}
                onClick={handleReset}
                >Set new password</Button>
                </div>
            </div>
            </>
    )
}