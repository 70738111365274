import RegistrationFields from "./Registration"
import { useState, useEffect } from "react";
import { useSearchParams } from 'react-router-dom';
import {postRequest} from "../../Tools/Requests.js";
import AlreadyRegistered from "./AlreadyRegustered.jsx";

export default function CreateAccount() {

    const [searchParams, setSearchParams] = useSearchParams()
    const token = searchParams.get("token")

    const [defaultEmail, setDefaultEmail] = useState("")
    const [newUser, setNewUser] = useState(true)

    const verifyEmail = () => {

        postRequest("/accounts/verify", {
            token: token
        })
        .then( async (res)=>{
            console.log(res)
            if (res.status === 403) {
                window.location = "/"
            } else if (res.status === 200) {
                setDefaultEmail(res.email_address)
            } else if (res.status === 500) {
                setNewUser(false)
            }

        })
    }

    useEffect(()=>
        verifyEmail()
    , [])


    return (
        <div className="login-background d-flex-column">
            { newUser ?
            <RegistrationFields authEndpoint={"/accounts/activate_account"}
             email_address={defaultEmail}
             token={token}
             />
             :
             <AlreadyRegistered />
            }
             
        </div>
    )
}