import { useEffect, useState } from "react"
import { Button, Skeleton } from "@mui/material"
import { Link } from "react-router-dom" 
import {MainButton} from "../Items/Buttons.jsx"
import { getRequestAuth } from "../../Tools/Requests.js"
import SearchIcon from '@mui/icons-material/Search';
import SearchBar from "../Items/SearchBar.jsx"

export default function WorkflowsList() {

    const [workflows, setWorkflows] = useState([])
    const [filtered, setFiltered] = useState([])
    const [loaded, setLoaded] = useState(false)

    useEffect(()=> {
        // get nodes and edges
        getRequestAuth("/workflows/get_workflows")
        .then((res) => {
            setWorkflows(res.blueprints);
            setFiltered(res.blueprints);
            setLoaded(true)
            }
        )
    }, [])

    const filterWorkflows = (term) => {
        const filteredWorkflows = workflows.filter((workflow) =>
          workflow.name.toLowerCase().includes(term.toLowerCase())
        );

        setFiltered(filteredWorkflows);
    };

    return (
        <div className="section-container">
            <div className="search-container">
                <Link to="/new-blueprint">
                    <MainButton text="New blueprint" />
                </Link>
               <SearchBar
                    searchHandler={filterWorkflows}
                    placeholder={"Search blueprints"}
               />
            </div>
            <div className="blueprint-list">
                {
                    loaded ?

                        filtered?.length > 0 ?
                        filtered.map(
                            (workflow, i) => 
                            <Link to={`/blueprint/${workflow.id}`} key={i}>
                                <div className="blueprint-box">
                                    <p>{workflow.name}</p>
                                </div>
                            </Link>
                        )
                        :
                        
                        <p>No blueprints yet</p>
                    :
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <Skeleton width={650} height={80}/>
                        <Skeleton width={650} height={80}/>
                        <Skeleton width={650} height={80}/>
                    </div>
                }
            </div>
        </div>
    )
}