import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import {Button, Tag, Divider} from 'antd';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import "./Box.css";
import FormDialog from './SelectUserModal';
import { useState } from 'react';
import {UsergroupAddOutlined} from '@ant-design/icons';
import { Tooltip } from '@mui/material';

export default function BasicCardAdmin({category, title, duration, tags, test_id, header, language }) {
  const [open, setOpen] = useState(false)

  return (
    <Card sx={{ minWidth: 275, border: "1px solid #e6e5e8", boxShadow: "none", maxWidth: 450 }}>
      <div style={{height: 5, backgroundColor: header}}></div>
      <CardContent>
        
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {category}
        </Typography>
        <Typography variant="h5" component="div">
          {title}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {duration}
        </Typography>
        <ul style={{listStyle: 'none', display: 'flex', gap: 5, padding: 0, flexWrap: "wrap"}}>
            {tags?.map((item, index) => <li key={index}><Tag color='default'>{item}</Tag></li>)}
        </ul>
        {language &&
        <Typography>
          <span className="box-languages">{language.source} to {language.target}</span>
        </Typography>
        }
      </CardContent>
      <Divider style={{padding:0, margin: "0 auto", width: "60%", minWidth: "60%"}}/>
      <CardActions sx={{display: "flex", justifyContent:"space-between"}}>
        <Link to={"/tests/" + test_id + "/details"}>
          <Button>Details</Button>
        </Link>
        <div>
          <Tooltip
            title="Add user to test."
          >
            <UsergroupAddOutlined onClick={()=>setOpen(true)} style={{fontSize: 18}}/>
          </Tooltip>
        </div>
      </CardActions>
      <FormDialog openDialog={open} handleClose={()=>setOpen(false)} testName={title} testId={test_id}/>
    </Card>
  );
}
