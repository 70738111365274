import { Link2Icon, EnvelopeClosedIcon, LightningBoltIcon, MoonIcon, ClockIcon, CheckCircledIcon, PlusCircledIcon, RocketIcon, PersonIcon, MinusCircledIcon, Pencil2Icon } from "@radix-ui/react-icons"

export const icons = {
    "Note edited": <Pencil2Icon
                      style={{
                        width: '14px',
                      }}
                      color="#EB984E"
                    />,
    "De-assigned from blueprint": <MinusCircledIcon
                                        style={{
                                            width: '14px',
                                        }}
                                        color="#FD3A69"
                                    />,
    "De-assigned from test": <MinusCircledIcon
                                style={{
                                width: '14px',
                                }}
                                color="#FD3A69"
                            />,
    "Profile updated": <PersonIcon
                            style={{
                            width: '14px',
                            }}
                        />,
    "Blueprint linked":<Link2Icon
                            style={{
                            width: '14px',
                            }}
                        />,
    "Test submission completed": <CheckCircledIcon
                                    style={{
                                    width: '14px',
                                    }}
                                />,
    "Account re-enabled": <LightningBoltIcon
                                style={{
                                width: '14px',
                                }}
                                color="#F1C40F"
                            />,
    "Account disabled": <MoonIcon
                            style={{
                            width: '14px',
                            }}
                            color="#1F618D"
                        />,
    "Test assigned": <PlusCircledIcon
                            style={{
                            width: '14px',
                            }}
                        />,
    "Account created": <RocketIcon
                            style={{
                            width: '14px',
                            }}
                            color="#1F618D"
                        />,
    "Account activation email sent": <EnvelopeClosedIcon
                                            style={{
                                            width: '14px',
                                            }}
                                            color="#EB984E"
                                        />
  }