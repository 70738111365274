import React, { memo, useEffect, useState } from 'react';
import { Handle, Position } from 'reactflow';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { PlayIcon } from '@radix-ui/react-icons';
import { Tooltip } from '@mui/material';

function CustomNode({ id, data }) {
  const [startColor, setStartColor] = useState("#909497")

  useEffect(()=> {
    data.initial ? setStartColor("#8E44AD") : setStartColor("#909497")
  }, [data.initial])
  // deletes node and associated edges
  const deleteNode = ()=> {
    
    data.test.setNodes((nodes) => nodes.filter((nd) => nd.id !== id))

    data.test.setEdges(
      (edges) => {
        return edges.filter((ed) => ed.source !== id && ed.target !== id)
      }
    )    
      
  }

  // Sets the new initial node
  const updateInitial = (id) => {
    data.test.setNodes((nodes) => nodes.map(n => ({
      ...n,
      data: {
        ...n.data,
        initial: n.id === id ? true : false
      }
    })));
  };
  
  return (
    <>
    <Handle style={{backgroundColor:"grey", top: 23}} type="target" position={Position.Left} id="connection-in-assessment"/>
      <div className="custom-node" onDragStartCapture={()=>console.log("up")}>
        <div className="custom-node-delete-node" onClick={deleteNode}>
          <CloseIcon style={{width: 15}}/>
        </div>
        <div className="custom-node-header">
        <div className="custom-node-header-icon">
            <DescriptionOutlinedIcon />
        </div>
        <p>Interpreter Assessment | {data.test.title}</p>
        </div>
        <div>
          <Tooltip title="Set as the start node">
            <PlayIcon 
                style={{cursor: "pointer", color: startColor, width: 20, height: 20, paddingLeft: 20}}
                onClick={()=>updateInitial(id)}/>
          </Tooltip>
        </div>
        <div className="custom-node-options">
          <p>Pass</p>
          <p>Fail</p>
        </div>
      </div>
      <div className="custom-node-handles">
        <Handle style={{top: 90}} type="source" position={Position.Right} id="pass" handle-id="pass" className='node-handle-pass' />
        <Handle style={{top: 110, backgroundColor: "red"}} type="source" position={Position.Right} id="fail" handle-id="fail" className='node-handle-fail'/>
      </div>
    </>
  );
}

export default memo(CustomNode);
