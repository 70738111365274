import { useParams } from "react-router-dom";
import MessageBox from "../Items/MessageBox";
import Assessment from "../Assessments/Assessment";
import "./TestContainer.css";
import { assessments } from "../../Database/assessments";
import { useEffect, useState } from "react";
import { getRequestAuth } from "../../Tools/Requests";

export default function TestContainer() {

    const {test_id} = useParams();
    const [currentAssessment, setCurrentAssessment] = useState({})

    const [confirmation, setConfirmation] = useState(false)

    function confirmWarning() {
        setConfirmation(true)
        return
    }
    
    useEffect(()=>{

        getRequestAuth(`/asmts/retrieve/user/${test_id}`)
        .then(async (res)=> {
            console.log(res)
            setCurrentAssessment(res[0])
        })
    }, [])

    return (
        <div className="section-container">
        { !confirmation ?
        <div className="message-box-container">
            <MessageBox
                title="Test requirements"
                message="Please provide your best interpretation in first person. Remember to say the corresponding scripts at the start and end of the interpretation session test. Speak clearly and loudly so that the client and the LEP can understand."
            />
            <MessageBox
            title="Read before starting the test"
            message="Please make sure that you have understood the above-mentioned requirements. The audio will start playing as soon as you proceed to the test section."
            showButton={true}
            action={confirmWarning}
            />
        </div>
        :
        <div>
            <Assessment currentAssessment={currentAssessment}/>
        </div>
        }
        </div>
    )
}