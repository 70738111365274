import React from 'react';
import { BaseEdge, EdgeLabelRenderer, getBezierPath } from 'reactflow';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';

export default function ButtonEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  data
}) {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const [closeIconOpacity, setCloseIconOpacity] = useState(0)

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style}/>
      <EdgeLabelRenderer>
        <div
          onMouseEnter={()=>setCloseIconOpacity(1)} onMouseLeave={()=>setCloseIconOpacity(0)}
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            // everything inside EdgeLabelRenderer has no pointer events by default
            // if you have an interactive element, set pointer-events: all
            pointerEvents: 'all',
          }}
          className="nodrag nopan"
        >
          <div className="custom-node-delete-node" onClick={(edges) => data.setEdges((edges) => edges.filter((ed) => ed.id !== id))}>
          <CloseIcon style={{width: 15}}/>
          </div>
        </div>
      </EdgeLabelRenderer>
    </>
  );
}