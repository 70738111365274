import { List } from "antd"
import { getRequestAuth } from "../../Tools/Requests"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"

export default function RecentDocuments() {
    const [documents, setDocuments] = useState([])
    
    const getDocuments = () => {
        getRequestAuth("/documents/all")
        .then((res)=> {
            if (res.success) {
                setDocuments(res.documents)
            }
        })
    }
    
    useEffect(()=> {
        getDocuments();
    }, [])
    
    return (
        <List
            style={{backgroundColor: "white", width: 700}}
            header={<h3 style={{padding:0, margin:0}}>Recent documents</h3>}
            size="small"
            bordered
            dataSource={documents}
            pagination={{
                pageSize: 5,
                }}
            renderItem={(doc) => (
                <List.Item>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <span style={{fontWeight: 500, fontSize: "small"}}>{doc.specs.type} | {doc.date.date}</span>
                        <Link to={`/documents/${doc.id}`}>{doc.title}</Link>
                    </div>
                </List.Item>
            )}
    />
    )
}