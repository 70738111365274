export default function AlreadyRegistered() {
    return(
        <div className="login-background d-flex-column">
            <div className="login-form-container">
                <div className="login-presentation" style={{marginBottom: 20}}>
                    <div className="login-presentation-text">
                    <h2>You are already a user <span style={{fontSize: "50px"}}>🎉</span></h2>
                    </div>
                </div>
            </div>
        </div>
    )
}