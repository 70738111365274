import { Timeline } from "antd"
import { useEffect, useState } from "react";
import { getRequestAuth } from "../../Tools/Requests";
import { useParams } from "react-router-dom";
import { icons } from "./TimelineIcons";

export default function CandidateRecordData({data}) {

    const [timelineItems, setTimelineItems] = useState([]);
  
    const {user_id} = useParams();
    
    useEffect(()=> {
      getRequestAuth("/timeline/retrieve/" + user_id)
      .then((res)=> {
        const items = res.map((i)=> (
            {
            label: i.date,
            dot: (
              icons[i.title]
            ),
            children: <>
                        <div>{i.title}</div>
                        <div style={{fontSize: 10, color: "smokegray"}}>{i.body}. {i.by ? `Action performed by ${i.by}` : ""}</div>
                        
                      </>,
            }
        ))

        setTimelineItems(items.reverse())

      })  
    }, [])
    
    
/*
    const items = [
          {
            // Done - pass
            label: "2015-09-01",
            dot: (
              
            ),
            children: <><div>{`Note edited.`}</div><div style={{fontSize: 10, color: "smokegray"}}>{"Compliance issues for interpreter"}</div></>,
          },
          {
            // Pass
            label: "2015-09-01",
            dot: (
              
            ),
            children: <><div>{`De-assigned from blueprint.`}</div><div style={{fontSize: 10, color: "smokegray"}}>{"Medical interpreter general test Spanish"}</div></>,
          },
          {
            label: "2015-09-01",
            dot: (
              <MinusCircledIcon
                style={{
                  width: '14px',
                }}
                color="#FD3A69"
              />
            ),
            children: <><div>{`.`}</div><div style={{fontSize: 10, color: "smokegray"}}>{"Exploration of the depths of the Pacific Ocean"}</div></>,
          },
          {
            // Done
            label: "2015-09-01",
            dot: (
              
            ),
            children: <><div>{``}</div><div style={{fontSize: 10, color: "smokegray"}}>{"Phone number: +94 232139900"}</div></>,
          },
          {
            // Done - Pass
            label: "2015-09-01",
            dot: (
              
            ),
            children: <><div>{``}</div><div style={{fontSize: 10, color: "smokegray"}}>{"Medical interpreter general test Spanish"}</div></>,
          },
          {
            // TODO
            label: "2015-09-01",
            dot: (
              
            ),
            children: <><div>{``}</div><div style={{fontSize: 10, color: "smokegray"}}>{"Exploration of the depths of the Pacific Ocean"}</div></>,
          },
          {
            // Done - pass
            label: "2015-09-01",
            dot: (
              
            ),
            children: ``,
          },
          {
            // Done - pass
            label: "2015-09-01",
            dot: (
              
            ),
            children: ``,
          },
          {
            // Done - Pass
            label: "2015-09-01",
            dot: (
              
            ),
            children: <><div>{``}</div><div style={{fontSize: 10, color: "smokegray"}}>{"Exploration of the depths of the Pacific Ocean"}</div></>,
          },
          {
            // Done - pass
            label: "2015-09-01",
            dot: (
              
            ),
            children: <><div>{`Account created.`}</div><div></div></>,
          },
          {
            // Done - pass
            label: "2015-09-01",
            dot: (
              
            ),
            children: <><div>{``}</div><div></div></>,
          },
    ]*/

    return (
        <div className="candidate-data-details">
                    <div className="candidate-data-table">
                        <div className="candidate-data-header"><p>Timeline</p></div>
                        <div className="candidate-data-body" >
                            <Timeline 
                                mode="left"
                                items={timelineItems}
                                style={{paddingLeft: "0px", marginLeft: -100, width: "110%"}}
                            />
                        </div>
                    </div>
                </div>
    )
}