import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import { LoadingButton, MainButton } from "../Items/Buttons"
import { getRequestAuth, putRequest } from "../../Tools/Requests"
import {Card, Input, Select, Radio, List} from "antd";
const {TextArea} = Input;

export default function SubmissionDetails() {
    const {submission_id} = useParams()
    const [submissionData, setSubmissionData] = useState({})
    const [test, setTest] = useState({})
    const [submissionStatus, setSubmissionStatus] = useState("")
    const [submissionNotes, setSubmissionNotes] = useState("")
    const [submissionScore, setSubmissionScore] = useState(0)

    const [sent, setSent] = useState(false)

    const navigate = useNavigate();

    useEffect(()=>{
        getRequestAuth(`/asmts/retrieve/admin/submission?submission_id=${submission_id}`)
        .then(async (res)=>{
            setSubmissionStatus(res.status)
            setSubmissionScore(res.score)
            setSubmissionNotes(res.comments)
            setSubmissionData(res)
            
            getRequestAuth(`/asmts/retrieve/admin/assessment?test_id=${res.creation_data.test_id}`)
            .then((subRes)=>{
                setTest(subRes.assessment)
            })
        })
        
    }, [])

    const updateSubmission = () => {

        setSent(true)
        putRequest("/asmts/update_submission", {
            submission_id: submission_id,
            comments: submissionNotes,
            status: submissionStatus,
            score: submissionScore 
        })
        .then((res)=>{
            setSent(false)
            navigate(`/tests/${test.test_id}/details`)           
        })
    }


    return(
        <div className="section-container">
            <h1>Submission details for submission {submission_id}</h1>
            <div className="submission-elements">
                <div className="submission-controls">
                    { submissionData.link_to_file &&
                    <audio controls>
                        <source src={submissionData.link_to_file} type="audio/mpeg" />
                        Your browser does not support the audio element.
                    </audio>
                    }
                    {submissionData.status &&
                    <Card
                        title="Grading"
                        size="small"
                    >
                        <div>
                            <p>Notes</p>
                            <TextArea 
                                placeholder="Reviewer notes"
                                rows={10}
                                onChange={(e)=>setSubmissionNotes(e.target.value)}
                                defaultValue={submissionData?.comments}
                            />
                        </div>
                        <div>
                            <p>Score</p>
                            <Select
                                style={{width: "100%"}}
                                onChange={(value) => setSubmissionScore(value)}
                                defaultValue={submissionData?.score}
                                placeholder="Score"
                                options={
                                    Array.from({length: 11}, (x, i) => i).map((s)=>({label: s, value: s}))
                                }
                            />
                        </div>
                        <div>
                        <p>Status</p>
                            <Radio.Group defaultValue={submissionData?.status ? submissionData.status : "pending"}
                            onChange={(e)=>setSubmissionStatus(e.target.value)} size="small">
                                <Radio.Button value="pending">Pending</Radio.Button>
                                <Radio.Button value="passed">Passed</Radio.Button>
                                <Radio.Button value="failed">Failed</Radio.Button>
                            </Radio.Group>
                        </div>
                    </Card>}
                </div>
                <Card
                    title="Transcripts"
                    size="small"
                >
                <div style={{display: "flex", gap: 10}}>
                    <div>
                        <p>Source</p>
                        <List
                            style={{backgroundColor: "white", width: 300}}
                            size="small"
                            bordered
                            dataSource={test?.transcript?.length > 0 ? test?.transcript?.split(".").slice(0, -1) : []}
                            renderItem={(doc) => (
                                <List.Item>
                                    <div style={{display: "flex", flexDirection: "column"}}>
                                        <span style={{fontSize: "small"}}>{doc}</span>
                                    </div>
                                </List.Item>
                            )}
                        />
                    </div>
                    <div>
                        <p>Interpretation</p>
                        <List
                            style={{backgroundColor: "white", width: 300}}
                            size="small"
                            bordered
                            dataSource={submissionData?.transcription?.content?.text > 0 ? submissionData?.transcription?.content?.text.split(".").slice(0, -1) : []}
                            renderItem={(doc) => (
                                <List.Item>
                                    <div style={{display: "flex", flexDirection: "column"}}>
                                        <span style={{fontSize: "small"}}>{doc}</span>
                                    </div>
                                </List.Item>
                            )}
                            />
                    </div>
                </div>
                
                </Card>
            </div>
            <div onClick={updateSubmission}>
                {
                    sent ?
                    <LoadingButton type="main" text="Submit"/>
                    :
                    <MainButton text="Submit" />
                }
            </div>
            
        </div>
    )
}