import { useState } from "react"
import {Modal, Button} from 'antd'
import { useParams, useNavigate } from "react-router-dom"
import { deleteRequest } from "../../Tools/Requests"
import { Card } from "antd"

export default function WorkflowsSettings() {

    const [openConfirm, setOpenConfirm] = useState(false)

    const navigate = useNavigate();

    const {blueprint_id} = useParams();

    const deleteBlueprint = ()=> {
        deleteRequest(`/workflows/delete/${blueprint_id}`)
        .then(()=> {
            navigate("/blueprints");
        })
    }

    return(
        <Card
            title={<h3 style={{padding:0, margin:0}} className="danger">Danger zone</h3>}
            style={{width: 350}}
            size="small"
        >
            <div>
                <Button danger onClick={()=>setOpenConfirm(true)}>
                    Delete blueprint
                </Button>
                <Modal 
                    open={openConfirm}
                    onCancel={()=>setOpenConfirm(false)}
                    onOk={()=> deleteBlueprint()}
                    okButtonProps={{ style: { backgroundColor: 'red' } }}
                    okText="Confirm deletion"
                >
                    <>
                        <p>You are about to delete this blueprint. This action cannot be undone. This action will:</p>
                        <ul>
                            <li>Delete the blueprint object.</li>
                            <li>Remove all links between your users and this blueprint.</li>
                            <li>Stop all blueprint sequences under way.</li>
                        </ul>
                    </>
                </ Modal>
            </div>
         </Card>
    )
}