import "../Login/Login.css";
import { useEffect, useState } from "react";
import BasicAlerts from "../Items/Alerts";
import { postRequestNoAuth } from "../../Tools/Requests";
import {Input, Button} from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

export default function RegistrationFields({authEndpoint, email_address, token}) {

    const [registered, setRegistered] = useState(false)  

    let [registerEmail, setRegisterEmail] = useState("");
    const [registerPassword, setRegisterPassword] = useState("");
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")

    const [sending, setSending] = useState(false)

    useEffect(()=> {
        setRegisterEmail(email_address)
    }, [email_address])

    const [errorEmail, setErrorEmail] = useState(false)
    const [errorFieldsRequired, setErrorFieldsRequired] = useState(false)

    const registerUser = () => {
        
        if (registerEmail.length === 0) {
            setRegisterEmail(email_address)
            registerEmail = email_address
        } else if (registerEmail.toLowerCase() !== email_address.toLowerCase()) {
            setErrorEmail(true)
            return
        }

        const errorField = [registerEmail, registerPassword, firstName, lastName].filter((field)=> {return field.length === 0})

        if (errorField.length > 0) {
            setErrorFieldsRequired(true)
            return
        }
        setSending(true)
        postRequestNoAuth(authEndpoint, {
            email_address: registerEmail,
            password: registerPassword,
            firstName: firstName,
            lastName: lastName,
            token: token
        })
        .then((res)=> {
            if (res.status === 200) {
                localStorage.setItem('token', res.token)
                setRegistered(true)

                window.location = "/";
            }
        })

    }

    return (
        <div className="login-background d-flex-column">
            <div className="login-form-container">
                <div className="login-presentation" style={{marginBottom: 20}}>
                    <div className="login-presentation-text">
                        {registered && <BasicAlerts type="success" message="Your account has been created successfully. Redirecting to login page."/>}
                        <h2>Create your account ✨</h2>
                    </div>
                </div>
                <div className="login-form-fields">
                    <Input 
                        placeholder="First name"
                        onChange={(event)=> setFirstName(event.target.value)}
                        onPressEnter={registerUser}
                        />
                    <Input
                        placeholder="Last name"
                        onChange={(event)=> setLastName(event.target.value)}
                        onPressEnter={registerUser}
                        />
                    <Input
                        placeholder="Email address" 
                        value={registerEmail}
                        onChange={(event)=> setRegisterEmail(event.target.value)}
                        onPressEnter={registerUser}
                        />  
                    <Input.Password 
                        onChange={(e)=>setRegisterPassword(e.target.value)}
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        onPressEnter={registerUser}
                    />
         
                    <div className="login-button-container">
                        {
                            errorEmail &&
                            <BasicAlerts type="error" message="Wrong email address." closeHandler={()=>setErrorEmail(false)}/>
                        }
                        {
                            errorFieldsRequired &&
                            <BasicAlerts type="error" message="Error. All fields are required." closeHandler={()=>setErrorFieldsRequired(false)}/>
                        }
                        <Button
                            loading={sending}
                            onClick={registerUser}
                        >Activate account</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}