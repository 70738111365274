import { useState, useEffect } from "react";
import dayjs from 'dayjs';
import { UploadOutlined } from '@ant-design/icons';
import {InfoCircledIcon} from '@radix-ui/react-icons'
import {Card, Input, Select, DatePicker, Checkbox, Radio, Upload, Button, ColorPicker, Tag} from 'antd';
import Tooltip from '@mui/material/Tooltip';
import { postRequestFormData } from "../../Tools/Requests";
import Caller from "./partials/Caller";
const {TextArea} = Input;

export default function TestEditor({tlanguages, sendInfo, testData, buttonLegend, loading}) {

    const [content, setContent] = useState(testData ? testData : {});
    const [submitDisabled, setSubmitDisabled] = useState(true)
    const [languages, setLanguages] = useState(tlanguages ? tlanguages : [])
    const [inputValue, setInputValue] = useState('');

    const specializations = ["Medical", "Legal", "Court", "Social Services", "Non-profits", "Emergency Services (911)", "Education"]

    const upload = ({file, onSuccess})=> {
        postRequestFormData("/api/audio/audiopost_admin", file, "file")
        .then((res)=> {
            if (res.success) {
                setContent((p)=> ({...p, link_to_file: res.url}))
                onSuccess("ok")
            }
        })
    }

    useEffect(()=> {
        const title = content?.title?.length > 0;
        const recording = (content?.fileuuid?.length > 0) || (content?.link_to_file?.length > 0);
        const sourceL = content?.language?.source?.length > 0;
        const targetL = content?.language?.target?.length > 0;
        if (title && recording && sourceL && targetL) {
            setSubmitDisabled(false)
        } else {
            setSubmitDisabled(true)
        }

    }, [content?.title, content?.fileuuid, content?.language?.source, content?.language?.target, content?.link_to_file])

    useEffect(()=>{
        const version = content?.version || "1.0.0";
        setContent((p)=>({...p, version: version}))
    }, [])

    const handleTagInputValue = (e)=> {
        setInputValue(e.target.value)
    }

    const handleTagInputConfirm = () => {
        if (inputValue.length > 0) {
            setContent((p)=>({...p, tags: [...p.tags, inputValue]}))            
        }

        setInputValue('');
      };

    const handleClose = (removedTag) => {
        const newTags = content.tags.filter((tag) => tag !== removedTag);
        setContent((p)=>({...p, tags: newTags}));
    };

    return(
        <div> 
            <div className="individual-inputs">
                <Card
                    style={{width: 600, display: "flex", flexWrap: "wrap", gap: 10}}
                >
                <ColorPicker defaultValue={content?.header_color ? content.header_color : "#28FFBF"}
                showText={()=><span>Header color</span>}
                onChange={(e)=>setContent((p)=>({...p, header_color: `rgba(${e.metaColor.r}, ${e.metaColor.g}, ${e.metaColor.b}, ${e.metaColor.a})`}))}
                />
                <Input 
                    variant="borderless"
                    placeholder="Test title"
                    defaultValue={content?.title}
                    style={{color:"#2E86C1", fontSize: "2rem", paddingLeft: 0}}
                    onChange={(e)=>{setContent((p) => ({...p, "title": e.target.value}))}}
                />
                <div style={{display: "flex", gap: 10, paddingTop: 20, paddingBottom: 20, flexWrap: "wrap"}}>
                    <div style={{width: 150}}>
                    <p>Source language</p>
                    <Select
                        style={{width: "100%"}}
                        placeholder="Source language"
                        onChange={(value) =>
                            setContent((p) => ({
                                ...p,
                                language: {
                                    ...p.language,
                                    source: value
                                }
                            }))
                        }
                        defaultValue={content?.language?.source}
                        options={languages?.map((language) => ({label: language.name, value: language.name})).sort((a, b) => a.label.localeCompare(b.label))}
                    />
                    </div>
                    <div style={{width: 150}}>
                    <p>Target language</p>
                    <Select
                        style={{width: "100%"}}
                        placeholder="Target language"
                        onChange={(value) =>
                            setContent((p) => ({
                                ...p,
                                language: {
                                    ...p.language,
                                    target: value
                                }
                            }))
                        }
                        defaultValue={content?.language?.target}
                        options={languages?.map((language) => ({label: language.name, value: language.name})).sort((a, b) => a.label.localeCompare(b.label))}
                    />
                    </div>
                    <div style={{width: 200}}>
                    <p>Specialization</p>
                    <Select
                        style={{width: "100%"}}
                        onChange={(value) =>
                            setContent((p)=>({...p, category: value}))
                        }
                        defaultValue={content?.category}
                        placeholder="Specialization"
                        options={
                            specializations.map((s)=>({label: s, value: s}))
                        }
                    />
                    </div>
                    <div style={{width: 150}}>
                        <p>Interpretation Mode</p>
                        <Select
                            style={{width: "100%"}}
                            placeholder="Interpretation Mode"
                            onChange={(value) =>
                                setContent((p) => ({
                                    ...p,
                                    interpretation_mode: value
                                }))
                            }
                            defaultValue={content?.interpretation_mode}
                            options={[{label: "Simultaneous", value: "simultaneous"}, {label: "Consecutive", value: "consecutive"}]}
                        />
                    </div>
                </div>
                
                </Card>
                <Card
                    style={{width: 600}}
                    title={<h3 style={{padding:0, margin:0}}>Labels</h3>}
                    size="small"
                >
                    <div>
                        <p>Tags help you quickly identify the content of your tests.</p>
                        <div style={{display:"flex", flexWrap: "wrap", gap: 5, paddingBottom: 5}}>
                            {
                                content?.tags?.length > 0 &&

                                content.tags.map((tag)=>
                                    <Tag
                                        closable
                                        onClose={(e) => {
                                        e.preventDefault();
                                        handleClose(tag);
                                        }}
                                    >
                                        {tag}
                                    </Tag>
                                )
                            }
                        </div>
                        <Input placeholder="Tags" defaultValue={content?.tags?.map((tag)=> `${tag}`)}
                        style={{width:540}}
                        value={inputValue}
                        onPressEnter={handleTagInputConfirm} 
                        onChange={handleTagInputValue}/>
                    </div>
                    <div style={{display: "flex", gap: 10, paddingTop: 20, paddingBottom: 20}}>
                        <div style={{width: 150}}>
                            <p>Difficulty level</p>
                            <Select
                                style={{width: "100%"}}
                                placeholder="Difficulty level"
                                onChange={(value) =>
                                    setContent((p) => ({
                                        ...p,
                                        difficulty: value
                                    }))
                                }
                                defaultValue={content?.difficulty}
                                options={[{label: "Easy", value: "easy"}, {label: "Moderate", value: "moderate"}, {label: "Intermediate", value: "intermediate"}, {label: "Hard", value: "hard"}, {label: "Expert", value: "expert"}]}
                            />
                        </div>
                        <div style={{width: 200}}>
                            <p>Test version</p>
                            <Input
                                style={{width: "100%"}}
                                defaultValue={content?.version || "1.0.0"}
                                disabled
                            />
                        </div>
                    </div>
                </Card>
                <Card
                    style={{width: 600}}
                    title={<h3 style={{padding:0, margin:0}}>Restrictions</h3>}
                    size="small"
                >
                    <div style={{display: "flex", gap: 10}}>
                        <div>
                            <p>Submission deadline</p>
                            <DatePicker 
                                onChange={(value, dateString) => {setContent((p)=>({...p, expiration_date: dateString}))}} 
                                onOk={(value, dateString) => {setContent((p)=>({...p, expiration_date: dateString}))}}
                                defaultValue={dayjs(content?.expiration_date, 'YYYY-MM-DD')}
                            />
                        </div>
                        <div>
                            <p>Allowed submissions</p>
                            <Input
                                onChange={(e)=> setContent((p)=>({...p, allowed_submissions: e.target.value}))}
                                placeholder="Submissions allowed"
                                type="number"
                                defaultValue={content?.allowed_submissions}
                            />
                        </div>
                    </div> 
                </Card>
                <div style={{display:"flex", gap: 10}}>
                <Card
                    title={<h3 style={{padding:0, margin:0}}>Test contents</h3>}
                    style={{width: 600}}
                    size="small"
                >
                    <div style={{display:"flex", flexDirection: "column", gap: 10}}>
                        <div className="test-upload-audio">
                            <p>Audio test content</p>
                            <div style={{display: "flex", alignItems: "center", gap: 15}}>
                                <Upload customRequest={upload}>
                                    <Button icon={<UploadOutlined />}>Upload new audio</Button>
                                </Upload>
                                {
                                    content?.link_to_file &&
                                    <audio style={{height: 35}} controls>
                                        <source src={content?.link_to_file} type="audio/mpeg" />
                                        Your browser does not support the audio element.
                                    </audio>
                                }
                            </div>

                        </div>
                        
                        <div style={{display: "flex", gap: 10, alignItems: "center"}}>
                            <p>Transcription mode
                                <Tooltip title="If you select 'Automatic', Swiftt will transcribe your audio file so that you can compare it against the interpreter submission.">
                                    <InfoCircledIcon style={{cursor:"pointer"}}/>
                                </Tooltip>
                            </p>
                            <Radio.Group defaultValue={content?.transcription_mode} onChange={(e)=>setContent((p)=>({...p, transcription_mode: e.target.value}))} size="small">
                                <Radio.Button value="automatic">Automatic</Radio.Button>
                                <Radio.Button value="manual">Manual</Radio.Button>
                            </Radio.Group>
                        </div>
                            {
                                content?.transcription_mode === "manual" &&
                            <div>
                                <p>Audio Transcript</p>
                                <TextArea
                                    placeholder="Audio transcript"
                                    multiline
                                    rows={10}
                                    onChange={(e)=>setContent((p)=>({...p, transcript: e.target.value}))}
                                    defaultValue={content?.transcript}
                                />
                            </div>
                            }
                        <div>
                            <p>Description</p>
                                <TextArea
                                    placeholder="Description"
                                    multiline
                                    rows={10}
                                    onChange={(e)=>setContent((p)=>({...p, description: e.target.value}))}
                                    defaultValue={content?.description}
                                />
                        </div>
                    </div>
                </Card>
                <Caller source={content?.language?.source} target={content?.language?.target} category={content?.category}/>
                </div>
                <Card
                    title={<h3 style={{padding:0, margin:0}}>Statuses</h3>}
                    style={{width: 600}}
                    size="small"
                >  
                <div style={{display: "flex", gap: 10}}>
                    <p>Completion Certificate</p>
                    <Checkbox 
                        defaultChecked={content?.completion_certificate}
                        onChange={(e)=>setContent((p)=>({...p, completion_certificate: e.target.checked}))}></Checkbox>
                </div>
                <div style={{display: "flex", gap: 10}}>
                    <p>Transcribe submissions 
                                <Tooltip title="Automatically transcribe and analyze your interpreter submissions.">
                                    <InfoCircledIcon style={{cursor:"pointer"}}/>
                                </Tooltip>
                    </p>
                    <Checkbox 
                        defaultChecked={content?.transcribe_submissions}
                        onChange={(e)=>setContent((p)=>({...p, transcribe_submissions: e.target.checked}))}></Checkbox>
                </div>
                <div style={{display: "flex", gap: 10, alignItems: "center"}}>
                    <p>Status</p>
                    <Radio.Group defaultValue={content?.status} onChange={(e)=>setContent((p)=>({...p, status: e.target.value}))} size="small">
                        <Radio.Button value="active">Active</Radio.Button>
                        <Radio.Button value="draft">Draft</Radio.Button>
                    </Radio.Group>
                </div>
                </Card>
            </div>
            <Tooltip title={submitDisabled ? "The following data is mandatory: title, language combination, audio file." : ""}>
                <span>
                <Button
                    disabled={submitDisabled}
                    loading={loading}
                    onClick={()=>sendInfo(content)}
                >{buttonLegend}</Button>
                </span>
            </Tooltip>
        </div>
    )
}