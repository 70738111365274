import KanbanBoard from "./Board";
import NewTaskForm from "./NewTaskForm";
import "./Tasks.css"
import {DragDropContext} from 'react-beautiful-dnd';
import { useState } from "react";
import _ from "lodash";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { useEffect } from "react";

const taskElements = {
    "item-9845": {
        borderTop: "red",
        title: "draft",
        id: "item-9845",
        items: []
    },

    "item-6956": {
        borderTop: "blue",
        title: "in progress",
        id: "item-6956",
        items: []
    },

    "item-6957": {
        borderTop: "yellow",
        title: "canceled",
        id: "item-6957",
        items: []
    },

    "item-6958": {
        borderTop: "green",
        title: "finished",
        id: "item-6958",
        items: []
    }
}


export default function BoardContainer() {

    const [state, setState] = useState(taskElements)
    const [displayNewTaskButton, setDisplayNewTaskButton] = useState("")
    const [displayNewTaskForm, setDisplayNewTaskForm] = useState("")

    const handleDragEnd = ({destination, source}) => {
        if (!destination) {
          return
        }
    
        if (destination.index === source.index && destination.droppableId === source.droppableId) {
          return
        }
       
        // Creating a copy of item before removing it from state
        const itemCopy = {...state[source.droppableId].items[source.index]}
    
        setState(prev => {
          prev = {...prev}
          // Remove from previous items array
          prev[source.droppableId].items.splice(source.index, 1)
    
    
          // Adding to new items array location
          prev[destination.droppableId].items.splice(destination.index, 0, itemCopy)
    
          return prev
        })
      }

    const addItemToArray = (taskTitle, comment, pipeline, boardId) => {
        const dup = state

        dup[boardId].items.push(
            {
                id: new Date().getTime().toString(),
                title: taskTitle,
                comment: comment,
                pipeline: pipeline
            }
        )
        setState(dup)
    }
    
    const closeForm = ()=> {
        setDisplayNewTaskForm("")
    }

    const removeTask = (boardId, taskId) => {
        const dup = state
        
        dup[boardId].items = dup[boardId].items.filter(item => item.id !== taskId);

        setState(dup)
    }

    return(
        <DragDropContext onDragEnd={handleDragEnd}>
        <div className="section-container">
            <div className="boards-container">
                {
                    _.map(state, (data, key) => {
                        
                        return(
                        <div className="board scrollable-content" key={key} onMouseEnter={()=>{setDisplayNewTaskButton(data.id)}} onMouseLeave={()=>{setDisplayNewTaskButton("")}}>
                            <h4 className={`border-top-${data.borderTop}`}>{data.title}</h4>
                            <div>
                                <KanbanBoard tasksArray={data} boardId={data.id} handleMarkAsDone={removeTask}/>
                                
                                <div className={`new-task-button ${displayNewTaskButton === data.id ? "" : "d-none"}`} onClick={()=> setDisplayNewTaskForm(data.id)}>
                                    <AddCircleOutlineOutlinedIcon sx={{color:"#3c3c3c", cursor:"pointer"}}
                                                                    />
                                    <p>New task</p>
                                </div>
                                <div className={`new-task-form ${displayNewTaskForm === data.id ? "" : "d-none"}`}>
                                    <NewTaskForm boardId={data.id} handleAddItem={addItemToArray} handleCancel={closeForm}/>
                                </div>
                            </div>
                        </div>)
                    }
                    )
                }
            </div>
        </div>
        </DragDropContext>
    )
}