import "./Profile.css";
import profilePicture from "../../../Assets/profile-picture.webp";
import BasicTabs from "../Tabs/Tabs.jsx";
import { useState, useEffect } from "react";
import ProfileInfo from "../Tabs/ProfileInfo";
import DataGridContainer from "../../Items/DataGrid.js"
import { getRequestAuth } from "../../../Tools/Requests";
import {Tabs} from 'antd'
import { personalPicture } from "../../Navigation/Sidebar/options/options.jsx";
import { useAtom } from "jotai";

export default function Profile () {

    const [username, setUsername] = useState("")
    const [userData, setUserData] = useState({})
    const [id, setId] = useState("")
    const [picture, setPicture] = useAtom(personalPicture)

    useEffect(()=> {
        getRequestAuth("/prl/profile_data")
        .then(async (res)=> {  
          setUsername(res.identity.first_name + " " + res.identity.last_name)
          setId(res.id)
          setUserData(res)
        })
    }, [])

    const columns = [
        { field: 'name', headerName: 'Task name', width: 250, align: 'left' },
        { field: 'email_address', headerName: 'Type', width: 120, align: 'left' },
        {
            field: 'date_invited',
            headerName: 'Status',
          minWidth: 120,
          align: 'left',
        },
        {
            field: 'account_type',
            headerName: 'Deadline',
          minWidth: 120,
          align: 'left'
        },
        {
            field: 'status',
            headerName: 'Status',
          minWidth: 50,
          align: 'left',
        },
        {
          field: 'score',
          headerName: 'Score',
          minWidth: 50,
          align: 'left',
        },
        {
          field: 'result',
          headerName: 'Result',
          minWidth: 50,
          align: 'left',
        },
      ];

    const tabs = [
      {
        key: 0,
        label: "Tests",
        children: <div className="sticky-head-table-profile"><DataGridContainer columns={columns} rows={[]} /></div>
      },
      {
        key: 1,
        label: "Edit profile",
        children: <ProfileInfo user={userData}/>
      }
    ]

    return(
        <div className="section-container d-flex-column-start height-100vh">
            <div className="profile-items-container profile-background-interpreter">
                <div className="profile-items">
                    <img src={picture ? picture : profilePicture} alt="Your profile" />
                    <div className="profile-texts">
                        <h2>{username} | {userData?.identity?.account_type}</h2>
                        <p>ID: {id}</p>
                        <div className="basic-tabs">
                            <Tabs 
                              items={tabs}
                            />
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}