import CsvDownloadButton from 'react-json-to-csv'
import {Button, Modal, Spin} from 'antd';
import { useState } from "react";
import ModalContent from "./ModalContent.jsx";
import { postRequest } from "../../../Tools/Requests.js";
import DataGridContainer from "../../Items/DataGrid.js";

export default function Data() {
    const [openModal, setOpenModal] = useState(false)
    const [tableData, setTableData] = useState({})
    const [request, setRequest] = useState({})
    const [loadingData, setLoadingData] = useState(false)
    const [downloadData, setDownloadData] = useState([]) 

    const onOk = () => {
        setLoadingData(true);
    
        // Create a new object with filtered columns, using functional state update to ensure freshness
        setRequest((currentRequest) => {
            const filteredColumns = currentRequest.columns.filter(c => c.ok === true);
            const newRequest = {
                ...currentRequest,
                columns: filteredColumns
            };
    
            // Close the modal
            setOpenModal(false);

            // Send this newRequest to the server
            postRequest("/analytics/" + newRequest.dataset, newRequest)
                .then((res) => {
                    if (res.success) {
                        // Update columns and rows for tableData based on the response
                        const columns = filteredColumns.map(c => ({
                            field: c.designation,
                            headerName: c.display,
                            align: "left",
                            minWidth: 150
                        }));
                        setTableData({ columns, rows: res.rows });
                    }
                    setLoadingData(false);
                    const dRows = res.rows.map(({ _id, id, ...rest }) => rest);
                    setDownloadData(dRows)

                })
                .catch(error => {
                    console.error('Error posting request:', error);
                    setLoadingData(false);
                });
    
            // Return the new state for request
            return newRequest;
        });
    };

    return (
        <>
            <Button
                onClick={()=>setOpenModal(true)}
                style={{backgroundColor: "#4FBBB7", color: "white", fontWeight: 600}}
                >Create table
            </Button>
            <Modal
                open={openModal}
                onCancel={()=>setOpenModal(false)}
                onOk={onOk}
            >
                <ModalContent setRequest={setRequest} />
            </Modal>
            {
                loadingData ?
                    <Spin style={{marginLeft: 150, marginTop: 20}} tip="Loading data" size="large"></Spin>
                
                :
                
                tableData?.columns &&

                <div style={{marginTop: 10, display: "flex", flexDirection: "column", gap: 10, alignItems: "flex-end"}}>
                    <CsvDownloadButton style={{cursor: "pointer", borderRadius: 6, padding: "4px 15px", color: "rgba(0, 0, 0, 0.88)", backgroundColor: "white", border: "1px solid #d9d9d9"}} 
                    data={downloadData} filename={"swiftt.csv"}/>
                    <DataGridContainer rows={tableData.rows} columns={tableData.columns} pageSize={9} boxWidth={915} title={request.dataset + " table"}/>
                </div>
            }
            

        </>
    )
}