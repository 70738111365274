import {Card, List} from 'antd';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { getRequestAuth } from '../../../Tools/Requests';

export default function CategoryCard() {
    const [categories, setCategories] = useState([])
    const [filteredCategories, setFilteredCategories] = useState([])
    
    const getCats = () => {
        getRequestAuth("/documents/categories")
        .then((res)=> {
            setCategories(res.categories);
            setFilteredCategories(res.categories);
        })
    }
    
    useEffect(()=> {
        getCats();
    }, [])
    
    return (

        <List
            style={{backgroundColor: "white", minWidth: 500, maxWidth: 700}}
            header={<h3 style={{padding:0, margin:0}}>Categories</h3>}
            size="small"
            bordered
            dataSource={categories}
            pagination={{
                pageSize: 5,
                }}
            renderItem={(category) => (
                <List.Item>
                    <Link to={`/documentation/category/${category.id}`}>{category.name}</Link>
                </List.Item>
            )}
    />
    )
}