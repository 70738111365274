import { useEffect, useState } from "react"
import BasicCard from "../Items/Box"
import MessageBox from "../Items/MessageBox"
import { getRequestAuth } from "../../Tools/Requests"
import TestSkeleton from "./TestSkeleton"

export default function UserTests({setAssessments, assessments}) {

    const [loaded, setLoaded] = useState(false)

    useEffect(()=> {
        getRequestAuth("/asmts/retrieve")
        .then(async (res)=> {
            setAssessments(res.result)
            setLoaded(true)
        })
    }, [])

    return (
        <div style={{display: "flex", gap: 10, flexWrap: "wrap", marginTop: 15}}>
            {
                loaded ?
                    assessments?.length > 0 ?
                        assessments.map((test, index) => 
                        
                            <BasicCard
                                category={test.category}
                                title={test.title}
                                duration={test.duration}
                                tags={test.tags}
                                test_id={test.test_id}
                                header_id={test.header_background}
                                language={test.language}
                                key={index}
                                />
                        )
                        :
                            <MessageBox title="No tests yet" message="Your organization hasn't assigned any tests to you yet." />
                    :
                    
                    <div style={{display: "flex", gap: 20, flexWrap: "wrap"}}>
                        <TestSkeleton />
                        <TestSkeleton />
                        <TestSkeleton />
                        <TestSkeleton />
                    </div>
            }
        </div>
    )
}