import { postRequest } from "../../Tools/Requests";
import UserSelector from "../Items/UserSelector";
import { useParams, Link } from "react-router-dom";
import DataGridContainer from "../Items/DataGrid";
import { useEffect, useState } from "react";
import { getRequestAuth, deleteRequest } from "../../Tools/Requests";
import RemoveUserButton from "./partials/RemoveUserButton";

import {Card} from 'antd'

export default function Assignees() {
    
    const {test_id} = useParams();
    const [testTitle, setTestTitle] = useState("")

    const [users, setUsers] = useState([]);

    const showUsers = ()=> {
        getRequestAuth(`/asmts/retrieve/admin/assessment_assignees/${test_id}`)
        .then((res)=> {
            setUsers(res.processedAssignees);
        })
    }

    useEffect(()=> {        
        showUsers();

        getRequestAuth(`/asmts/retrieve/admin/assessment/title?test_id=${test_id}`)
        .then(async (res)=>{ 
            if (res.success) {
                setTestTitle(res.title)
            }   
            
        })
        
    }, [])

    const addUserstoTest = (idList) => {
        
        return postRequest("/asmts/assign_to_users",{
            test_id: test_id,
            user_ids: idList,
            testName: testTitle
        })
        .then((res)=> {
            showUsers();     
        });
        
    }

    const removeUser = (id) => {
        
        deleteRequest(`/asmts/remove_user/${id}`, 
            {
             testId: test_id,
             testName: testTitle   
            }
        )
        .then( ()=> showUsers())
        
       return;
    }

    const columns = [
        {
            field: 'name', headerName: 'Name', width: 200,
            renderCell: (params) => <div><Link to={`/candidate/${params.row.id}`}>{params.row.name}</Link></div>,
            valueGetter: (params) => params.row.name
        },
        {
            field: 'email_address', headerName: 'Email Address', width: 250,
            renderCell: (params) => <div>{params.row.email_address}</div>,
            valueGetter: (params) => params.row.email_address
        },
        {
            field: 'agent', headerName: 'Assigned by', width: 150,
            renderCell: (params) => <div>{params.row.agent}</div>,
            valueGetter: (params) => params.row.agent
        },
        {
            field: 'date', headerName: 'Date assigned', width: 120,
            renderCell: (params) => <div>{params.row.date}</div>,
            valueGetter: (params) => params.row.date
        },
        {
            field: "id", headerName: "Action", width: 100,
            renderCell: (params) => <RemoveUserButton removeUser={()=> removeUser(params.row.id)}/>
        }
    ]

    return (
        <div style={{display:"flex", flexWrap: "wrap", gap: 10}}>
            <Card
                title={<h3 style={{padding:0, margin:0}}>Add interpreters to this test</h3>}
                style={{width: 600, height: "100%"}}
                size="small"
            >
            <UserSelector 
                successMessage={"Users successfully added to test."}
                addUsersHandler={addUserstoTest}
                style={{width: 500}}
            />
            </Card>
            <div className="sticky-head-table-manager" style={{marginLeft: 0}}>
                <DataGridContainer rows={users} columns={columns} title={"Interpreters assigned"}/>
            </div>
        </div>
    )
}