import "./Tasks.css"
import { Droppable, Draggable } from "react-beautiful-dnd"
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { useState } from "react";

export default function KanbanBoard({tasksArray, handleMarkAsDone, boardId}) {

    const [taskId, setTaskId] = useState("")

    const handleTaskMarkAsDone = (boardId, id) => {
        handleMarkAsDone(boardId, id)
    }

    return(
        
            <Droppable droppableId={tasksArray.id}>
                {(providedDroppable, snapshotDroppable) => { return(
                    <ul className="board-layout" {...providedDroppable.droppableProps} ref={providedDroppable.innerRef}>
                        {
                            tasksArray.items.map(
                                ({id, title, comment, pipeline}, index) => {return (
                                <Draggable key={id} draggableId={id} index={index}>
                                    {
                                        (providedDraggable, snapshotDraggable) => (
                                            <li {...providedDraggable.draggableProps}
                                                ref={providedDraggable.innerRef}
                                                {...providedDraggable.dragHandleProps}
                                                className={`kanban-item ${taskId === id ? "d-none" : ""}`}>
                                                
                                                <div>{title}</div>
                                                <div className="task-comment">Comment: {comment}</div>
                                                <div className="task-pipeline">Blueprint: {pipeline}</div>
                                                <div className="mark-as-done" onClick={()=>{handleTaskMarkAsDone(boardId, id); setTaskId(id);}}><p>Mark as done</p> <CheckCircleOutlinedIcon/></div>
                                                
                                            </li>)
                                        
                                    }
                                </Draggable>
                                )} 
                            )
                        }
                        {providedDroppable.placeholder}
                    </ul>
                )}}
            </Droppable>
        
    )
}